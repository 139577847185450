
import { defineComponent, inject, PropType, Ref } from 'vue'
import Dropdown from 'primevue/dropdown';
import { EndroitDTO } from '../DTO/EndroitDTO'
import { EndroitSelectionHelper } from '@/helpers/EndroitSelectionHelper';
export default defineComponent({
  name: 'RechercheEndroitTexteUneBoite',
  props: {
    endroitsData: {
      type: Array as PropType<EndroitDTO[]>,
      required: true
    }
  },
  components: {
    Dropdown
  },
  setup () {
    const endroits = inject('endroits') as Ref<Array<EndroitDTO>>
    const endroitSelectionHelper = inject('endroitSelectionHelper') as Ref<EndroitSelectionHelper>
    return {
      endroits,
      endroitSelectionHelper
    }
  },
  data () {
    return {
      municipaliteSelectionnee: null as EndroitDTO | null,
      dropdownDataMunicipalite: new Array<any>()
    }
  },
  watch: {
    '$route' (to, from) {
      this.setDropdownData();
    }
  },
  methods: {
    setDropDownDataMunicipalite () {
      this.dropdownDataMunicipalite = this.getSortedEndroitArray(this.endroitsData.filter(endr => {
        return !this.endroits.includes(endr)
      })).map((item) => {
        if (item.typeEndroit === 'mrc') {
          // console.log(item.typeEndroit)
          item.typeEndroit = 'MRC';
        }

        if (item.typeEndroit === 'region') {
          // console.log(item.typeEndroit)
          item.typeEndroit = 'Région';
        }

        item.displayName = item.NOM_GEO + ' (' + item.typeEndroit + ')';
        if (item.typeEndroit === 'municipalite') {
          item.displayName = item.NOM_GEO + ' (municipalité)';
        }
        if (item.typeEndroit === 'municipalite' && item.NOM_MRC !== undefined) {
          item.displayName += ' - ' + item.NOM_MRC + ' (MRC)';
        }
        return item;
      });
      this.municipaliteSelectionnee = null;
    },
    async ajouterMunicipalite () {
      this.$loading.startLoading();
      const drop = this.$refs.dropdown as any;
      drop.filterValue = null;
      const endroitSelectionnee = this.municipaliteSelectionnee;
      if (endroitSelectionnee !== null) {
        this.endroitSelectionHelper.addEndroitAndHandleType(endroitSelectionnee, this.$toast, this.$store).then(() => {
          this.$emit('onEndroitUpdated');
        });
      }
    },
    setDropdownData () {
      this.setDropDownDataMunicipalite();
    },
    getSortedEndroitArray (endroits: EndroitDTO[]) {
      return endroits.sort((a, b) => {
        if (a.NOM_GEO < b.NOM_GEO) {
          return -1
        } else if (a.NOM_GEO > b.NOM_GEO) {
          return 1
        } else {
          return 0
        }
      });
    }
  },
  mounted () {
    this.setDropdownData();
  }
})

