import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PrimeVue from 'primevue/config'
import Toast, { PluginOptions, useToast } from 'vue-toastification'
import ConfirmationService from 'primevue/confirmationservice';
import * as am4core from '@amcharts/amcharts4/core';
import LoadingPlugin from './plugins/Loading/LoadingPlugin'

import 'vue-toastification/dist/index.css'

import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css';
import './plugins/Loading/LoadingStyle.css'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import Dialog from 'primevue/dialog';

const app = createApp(App).use(store)
const options: PluginOptions = {
  // You can set your default options here
};

app.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY, loaderOptions: {} })
app.use(Toast, options);
app.config.globalProperties.$toast = useToast();
app.config.globalProperties.$store = store;
am4core.options.autoDispose = true;
am4core.addLicense('MP314545339');
am4core.addLicense('CH314545339');
app.use(PrimeVue);
app.use(LoadingPlugin);
app.component('Dialog', Dialog);
app.use(ConfirmationService);
app.use(router).mount('#app')
