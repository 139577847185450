import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "error-box"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.errorMessage.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_Dropdown, {
      onChange: _ctx.onValueChange,
      optionLabel: "texteReponse",
      optionValue: _ctx.optionValue,
      options: _ctx.optionData,
      placeholder: "Sélectionner...",
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      class: "dropdown"
    }, null, 8, ["onChange", "optionValue", "options", "modelValue"])
  ], 64))
}