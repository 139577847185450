
import { defineComponent, inject, PropType, Ref } from 'vue'
import Dropdown from 'primevue/dropdown';
import { EndroitDTO } from '../DTO/EndroitDTO'
import { EndroitSelectionHelper } from '@/helpers/EndroitSelectionHelper';
export default defineComponent({
  name: 'RechercheEndroitParRepondant',
  props: {
    endroitsData: {
      type: Array as PropType<EndroitDTO[]>,
      required: true
    }
  },
  components: {
    Dropdown
  },
  setup () {
    const endroits = inject('endroits') as Ref<Array<EndroitDTO>>
    const endroitSelectionHelper = inject('endroitSelectionHelper') as Ref<EndroitSelectionHelper>
    return {
      endroits,
      endroitSelectionHelper
    }
  },
  data () {
    return {
      municipaliteSelectionnee: null as EndroitDTO | null,
      dropdownDataMunicipalite: new Array<any>(),
      questionnaireIndex: null,
      repondantParQuestionnaire: new Array<any>()
    }
  },
  methods: {
    async ajouterMunicipalite () {
      const endroitSelectionnee = this.municipaliteSelectionnee;
      if (endroitSelectionnee !== null) {
        this.$loading.startLoading();
        this.endroitSelectionHelper.addEndroitAndHandleType(endroitSelectionnee, this.$toast, this.$store).then(() => {
          this.$emit('onEndroitUpdated');
        });
      } else if (this.questionnaireIndex !== null) {
        this.$loading.startLoading();
        this.endroitSelectionHelper.addMultipleEndroits(this.dropdownDataMunicipalite.map(end => end._id)).then(() => {
          this.$toast.success('Les endroits ont bien été ajoutés.');
          this.$emit('onEndroitUpdated');
        });
      }
    },
    setDropdownData (endroit: any) {
      console.log(endroit)
    },
    questionnaireChange () {
      this.dropdownDataMunicipalite = [];
      if (this.questionnaireIndex === 'Bilan de santé') {
        if (this.repondantParQuestionnaire != null) {
          this.repondantParQuestionnaire.forEach((element: any) => {
            if (element.q1 === true) {
              const endroit = this.endroitsData.find((endroit: any) => endroit.slug === element.slug);
              this.dropdownDataMunicipalite.push(endroit);
            }
          });
        }
      } else if (this.questionnaireIndex === 'Municipalité') {
        if (this.repondantParQuestionnaire != null) {
          this.repondantParQuestionnaire.forEach((element: any) => {
            if (element.q2 === true) {
              console.log(element);
              const endroit = this.endroitsData.find((endroit: any) => endroit.slug === element.slug);
              this.dropdownDataMunicipalite.push(endroit);
            }
          });
        }
      } else if (this.questionnaireIndex === 'Organisation') {
        if (this.repondantParQuestionnaire != null) {
          this.repondantParQuestionnaire.forEach((element: any) => {
            if (element.q3 === true) {
              console.log(element);
              const endroit = this.endroitsData.find((endroit: any) => endroit.slug === element.slug);
              this.dropdownDataMunicipalite.push(endroit);
            }
          });
        }
      }
      this.displayNameAttributName();
    },
    getSortedEndroitArray (endroits: EndroitDTO[]) {
      return endroits.sort((a, b) => {
        if (a.NOM_GEO < b.NOM_GEO) {
          return -1
        } else if (a.NOM_GEO > b.NOM_GEO) {
          return 1
        } else {
          return 0
        }
      });
    },
    displayNameAttributName () {
      this.dropdownDataMunicipalite.forEach(element => {
        if (element.displayName === undefined) {
          element.displayName = element.NOM_GEO + ' (municipalité) - ' + element.NOM_MRC;
        }
      });
    }
  },
  mounted () {
    this.$store.dispatch('questionnaire/getEndroitRepondant', { questionnaireIndex: this.questionnaireIndex }).then((response) => {
      this.repondantParQuestionnaire = response;
    })
  }
})

