import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38d85d85"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "error-box"
}
const _hoisted_2 = { class: "textareaLabel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Textarea = _resolveComponent("Textarea")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.errorMessage.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true),
    _createElementVNode("label", _hoisted_2, [
      _createElementVNode("strong", null, _toDisplayString(_ctx.questionProp.titreImport), 1)
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_Textarea, {
        onInput: _ctx.onValueChange,
        autoResize: true,
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        rows: "5",
        cols: "30"
      }, null, 8, ["onInput", "modelValue"])
    ])
  ], 64))
}