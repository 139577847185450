
import { defineComponent } from 'vue';
import InputTextMaison from '@/components/input/InputTextMaison.vue';
import InputMultiIntegerMaison from '@/components/input/InputMultiIntegerMaison.vue';
import InputNumberMaison from '@/components/input/InputNumberMaison.vue';
import InputDropdownMaison from '@/components/input/InputDropdownMaison.vue';
import InputCheckboxesMaison from '@/components/input/InputCheckboxesMaison.vue';
import InputRadioMaison from '@/components/input/InputRadioMaison.vue';
import InputGrilleRadioMaison from '@/components/input/InputGrilleRadioMaison.vue';
import { QuestionnaireDTO } from '@/DTO/QuestionnaireDTO';
import { QuestionnaireDropdownChangeResponse } from '@/DTO/QuestionnaireDropdownChangeResponse';
import InputMultiTextMaison from '../components/input/InputMultiTextMaison.vue';
import InputTextareaMaison from '@/components/input/InputTextareaMaison.vue';
import InputTagMaison from '../components/input/InputTagMaison.vue';
import { FilterKeyValue } from '@/DTO/FilterKeyValue';
import QuestionnaireSaved from '@/components/QuestionnaireSaved.vue';
import endroit from '@/store/endroit';
// PrimeVue

export default defineComponent({
  name: 'Questionnaire',
  components: {
    InputNumberMaison,
    InputDropdownMaison,
    InputTextMaison,
    InputCheckboxesMaison,
    InputGrilleRadioMaison,
    InputRadioMaison,
    InputMultiTextMaison,
    InputTextareaMaison,
    InputTagMaison,
    QuestionnaireSaved,
    InputMultiIntegerMaison
  },
  data () {
    return {
      // fichier JSON contenant tout le matériel pour générer un formulaire
      questionnaire: {} as QuestionnaireDTO,
      errors: false,
      questionnaireSaved: false,
      municipalitesAlreadySaved: [],
      regions: [],
      mrc: [],
      municipalites: []
    }
  },
  methods: {
    dropdownChange (response: QuestionnaireDropdownChangeResponse) {
      for (const [key, section] of Object.entries(this.questionnaire.sections)) {
        if (section.contenuSection) {
          for (var i = 0; i < section.contenuSection.length; i++) {
            var question = section.contenuSection[i];
            if (question.dependsOn) {
              if (question.dependsOn.parentId === response.questionId) {
                if (!question.filterWith) {
                  question.filterWith = {} as FilterKeyValue;
                }
                question.filterWith.parentId = response.responseId;
                if (question.selfInputComponent.updateFilter) {
                  question.selfInputComponent.updateFilter();
                }
              }
            }
          }
        }
      }
    },
    getForm () {
      (this.$refs.champs as any).forEach((element: any) => {
        if (element.validate) {
          element.validate();
          if (element.errorMessage.length > 0) {
            this.errors = true;
          }
        }
      });
      // variables
      const myForm: any = {};
      let objQuestionActuelle: any = {};

      // boucle cherchant une à une les questions et réponses fournies par l'utilisateur
      for (const [key, section] of Object.entries(this.questionnaire.sections)) {
        if (section.contenuSection) {
          if (!(key in myForm)) {
            myForm[key] = {};
          }
          for (const [questionId, question] of Object.entries(section.contenuSection)) {
            objQuestionActuelle = { question_id: questionId, question: question.question, reponse: question.reponseChoisie, reponsesImporter: question.reponsesImporter };
            myForm[key][questionId.toString()] = objQuestionActuelle;
            if (question.sousQuestions !== undefined) {
              for (const [sousQuestionId, sousQuestion] of Object.entries(question.sousQuestions)) {
                objQuestionActuelle = { question_id: sousQuestionId, question: sousQuestion.question, reponse: sousQuestion.reponseChoisie, reponsesImporter: sousQuestion.reponsesImporter, question_id_parent: questionId };
                myForm[key][sousQuestionId.toString()] = objQuestionActuelle;
              }
            }
          }
        }
      }

      // TODO: dont submit if errors
      if (!this.errors) {
        this.submit(myForm);
      }

      this.errors = false;
    },
    submit (myForm: any) {
      this.$loading.startLoading();
      let endroitId = myForm as any;
      console.log(this.questionnaire);
      const endroitReponses = this.municipalitesAlreadySaved[myForm.informationGenerale[6].reponse.id] as any;
      if (endroitReponses[this.questionnaire.id] === true) {
        this.$toast.error('La municipalité a déjà répondu au questionnaire');
        this.$loading.stopLoading();
      } else {
        this.questionnaire.idQuestionEndroit.forEach((id) => {
          endroitId = endroitId[id];
        });
        endroitId = endroitId.reponse.id;
        this.$store.dispatch('questionnaire/saveQuestionnaire', { questionnaire: myForm, endroitId, questionnaireId: this.$route.params.id }).then((response) => {
          this.$loading.stopLoading();
          // this.$toast.success('Le questionnaire a été envoyé avec succès.');
          this.questionnaireSaved = true;
        })
      }
    },
    formatQuestionnaire () {
      const sectionsKeys = Object.keys(this.questionnaire.sections);
      sectionsKeys.forEach((sectionKey) => {
        const section = this.questionnaire.sections[sectionKey];
        if (section.contenuSection) {
          for (const question of Object.values(section.contenuSection)) {
            question.setSelfInputConponent = (component) => {
              question.selfInputComponent = component;
            }
          }
        }
      });
    }
  },
  mounted () {
    this.$loading.startLoading();
    this.$store
      .dispatch('questionnaire/getQuestionnaire', { questionnaireName: this.$route.params.id })
      .then((response) => {
        console.log(response);
        this.questionnaire = response[0];
        this.municipalitesAlreadySaved = response[1];
        this.formatQuestionnaire();
        this.regions = response[0].sections.informationGenerale.contenuSection[4].reponsesPossibles;
        this.mrc = response[0].sections.informationGenerale.contenuSection[5].reponsesPossibles;
        this.municipalites = response[0].sections.informationGenerale.contenuSection[6].reponsesPossibles;
        this.$loading.stopLoading();
      });
  }
})
