// avant

import { Module } from 'vuex';

const formulaires: Module<any, any> = {
  namespaced: true,
  state: {
    str: 'Type the name of a color',
    colorCode: 'red',
    form1: {
      nom: null,
      prenom: null,
      courriel: null
    },
    form2: {

    },
    form3: {

    }
  },
  getters: {
    getColor (state) {
      return state.colorCode;
    }
  },
  actions: {
    fetchColor ({ commit }, param) {
      commit('setColorCode', param);
    }
  },

  mutations: {
    setColorCode (state, newValue) {
      state.colorCode = newValue;
    }
  }
}

export default formulaires;

// maintenant

// import { createStore } from 'vuex'

// export default createStore({
//   state: {
//     str: 'Type the name of a color',
//     colorCode: 'red',
//     form1: {
//       nom: null,
//       prenom: null,
//       courriel: null
//     },
//     form2: {

//     },
//     form3: {

//     }
//   },
//   getters: {

//   },
//   actions: {

//   },

//   mutations: {
//     setColorCode (state, newValue) {
//       state.colorCode = newValue;
//     }
//   }
// })
