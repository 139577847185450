import { EndroitDTO } from '@/DTO/EndroitDTO';
import { RouteLocationNormalizedLoaded, Router } from 'vue-router';
import { ToastInterface } from 'vue-toastification';
import { Store } from 'vuex';
import { UrlQueryDeserialiser } from './UrlQueryDeserialiser';

enum GetAllNewDesactivatedEndroitsMode {
  ADD_ENDROIT,
  REMOVE_ENDROIT
}

export class EndroitSelectionHelper {
    private urlQueryDeserialiser: UrlQueryDeserialiser;
    private route: RouteLocationNormalizedLoaded;
    private router: Router;

    public constructor (urlQueryDeserialiser: UrlQueryDeserialiser, route: RouteLocationNormalizedLoaded, router: Router) {
      this.urlQueryDeserialiser = urlQueryDeserialiser;
      this.route = route;
      this.router = router;
    }

    public getAllSelectedEndroits (): Array<string> {
      const allEndroitsSelected = this.getAllEndroitsWithoutConsideringActivationStatus();
      const desactivatedEndroit = this.getAllDesactivatedEndroitsFromAllSelectedEndroits(allEndroitsSelected);
      return allEndroitsSelected.filter(id => !desactivatedEndroit.includes(id));
    }

    public getAllDesactivatedEndroits (): Array<string> {
      const allEndroitsSelected = this.getAllEndroitsWithoutConsideringActivationStatus();
      return this.getAllDesactivatedEndroitsFromAllSelectedEndroits(allEndroitsSelected);
    }

    public getAllEndroitsWithoutConsideringActivationStatus (): Array<string> {
      return this.urlQueryDeserialiser.getArrayFromRoute(this.route, 'endroits');
    }

    private getAllDesactivatedEndroitsFromAllSelectedEndroits (allEndroitsSelected: Array<string>): Array<string> {
      return this.urlQueryDeserialiser.getArrayFromRoute(this.route, 'endroitsDesactives', (val) => allEndroitsSelected[parseInt(val)]);
    }

    public addEndroitAndHandleType (endroit: EndroitDTO, $toast: ToastInterface, $store: Store<{ count: number }>): Promise<void> {
      return new Promise((resolve, reject) => {
        if (endroit.typeEndroit === 'municipalite') {
          this.addEndroit(endroit).then(() => {
            $toast.success("L'endroit a bien été ajouté.");
            resolve();
          }).catch(() => {
            resolve();
          });
        } else {
          $store.dispatch('endroit/getAllChildOfEndroit', endroit._id).then((endroitsIdList) => {
            const endroitList = this.getAllEndroitsWithoutConsideringActivationStatus();
            for (let x = 0; x < endroitsIdList.length; x++) {
              const endroit = endroitsIdList[x];
              if (!endroitList.includes(endroit._id)) {
                endroitList.push(endroit._id);
              }
            }
            this.addMultipleEndroits(endroitList).then(() => {
              $toast.success("L'endroit a bien été ajouté.");
              resolve();
            }).catch(() => {
              resolve();
            });
          });
        }
      });
    }

    public addEndroit (endroit: EndroitDTO): Promise<void> {
      return new Promise((resolve, reject) => {
        const oldEndroits = this.getAllEndroitsWithoutConsideringActivationStatus();
        if (oldEndroits.includes(endroit._id)) {
          console.error('Endroit deja selectionne')
          reject(Error('Endroit deja selectionne'));
        } else {
          this.urlQueryDeserialiser.addToUrlArray({ route: this.route, router: this.router }, 'endroits', endroit._id).then(() => {
            resolve();
          })
        }
      });
    }

    public activateEndroit (endroit: string): Promise<void> {
      return new Promise((resolve) => {
        this.urlQueryDeserialiser.removeToUrlArray({ route: this.route, router: this.router }, 'endroitsDesactives', endroit).then(() => {
          resolve();
        })
      });
    }

    public deactivateEndroit (endroit: string): Promise<void> {
      return new Promise((resolve) => {
        this.urlQueryDeserialiser.addToUrlArray({ route: this.route, router: this.router }, 'endroitsDesactives', endroit).then(() => {
          resolve();
        });
      });
    }

    public addMultipleEndroits (endroitsList: Array<string>): Promise<void> {
      return new Promise((resolve) => {
        const allEndroitsSelected = this.getAllEndroitsWithoutConsideringActivationStatus();
        endroitsList.forEach(end => {
          if (!allEndroitsSelected.includes(end)) {
            allEndroitsSelected.push(end);
          }
        });
        this.urlQueryDeserialiser.setUrlArrayFromArray({ route: this.route, router: this.router }, 'endroits', allEndroitsSelected).then(() => {
          resolve();
        });
      });
    }

    private getAllNewDesactivatedEndroits (oldDesactivatedEndroitsArrayIndex: Array<number>, addedOrRemovedEndroitIndex: number, mode: GetAllNewDesactivatedEndroitsMode): Array<number> {
      const newDesactivatedEndroitsArrayIndex = new Array<number>();
      oldDesactivatedEndroitsArrayIndex.forEach((endId: number) => {
        const endIdNumber = +endId;
        if (endIdNumber > addedOrRemovedEndroitIndex) {
          const toAdd = (mode === GetAllNewDesactivatedEndroitsMode.REMOVE_ENDROIT) ? -1 : 1;
          const newId = endIdNumber + toAdd;
          newDesactivatedEndroitsArrayIndex.push(newId);
        } else if (endIdNumber !== addedOrRemovedEndroitIndex) {
          newDesactivatedEndroitsArrayIndex.push(endIdNumber);
        }
      });
      return newDesactivatedEndroitsArrayIndex;
    }

    public removeEndroit (endroit: EndroitDTO, id?: number): Promise<void> {
      return new Promise((resolve, reject) => {
        const allEndroitsSelected = this.getAllEndroitsWithoutConsideringActivationStatus();
        const endroitArrayIndex = (id !== undefined) ? id : allEndroitsSelected.findIndex((end) => endroit._id === end);
        if (endroitArrayIndex === -1) {
          reject(new Error("L'endroit n'est pas dans l'url."));
        } else {
          const desactivatedEndroitsArrayIndex = this.urlQueryDeserialiser.getArrayFromRoute(this.route, 'endroitsDesactives');
          const newDesactivatedEndroitsArrayIndex = this.getAllNewDesactivatedEndroits(desactivatedEndroitsArrayIndex, endroitArrayIndex, GetAllNewDesactivatedEndroitsMode.REMOVE_ENDROIT);
          allEndroitsSelected.splice(endroitArrayIndex, 1);
          this.urlQueryDeserialiser.setMultipleUrlArrayFromMultipleArray({ route: this.route, router: this.router }, ['endroits', 'endroitsDesactives'], [allEndroitsSelected, newDesactivatedEndroitsArrayIndex]).then(() => {
            resolve();
          });
        }
      });
    }

    public removeAllEndroit (): Promise<void> {
      return new Promise((resolve, reject) => {
        this.urlQueryDeserialiser.removeUrlArray({ route: this.route, router: this.router }, 'endroits').then(() => {
          resolve();
        });
      });
    }
}
