import axios from 'axios'
import { Module } from 'vuex'
const recensement: Module<any, any> = {
  namespaced: true,
  state: {
    graphique: new Map<number, any>(),
    graphiqueErrors: new Map<number, any>(),
    graphiqueReponsesPossibles: new Map<number, any>()
  },
  getters: {
    getGraph: (state) => (id: number) => {
      return state.graphique[id];
    },
    getGraphErrors: (state) => (id: number) => {
      return state.graphiqueErrors[id];
    },
    getGraphReponsesPossibles: (state) => (id: number) => {
      return state.graphiqueReponsesPossibles[id];
    }
  },
  actions: {
    fetchAllGraphics ({ commit }, { requestBody }) {
      return new Promise((resolve, reject) => {
        axios.request({
          url: '/getAllRecensementsInOneQuery',
          method: 'post',
          baseURL: process.env.VUE_APP_API_URL,
          headers: {
            Authorization: axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
          },
          data: requestBody
        }).then((response: any) => {
          const data = response.data;
          data.forEach((element: any) => {
            commit('setGraphique', { id: element.id, graphique: element.data, displayFields: element.displayFields, errors: element.errors, reponsesPossibles: element.reponsesPossibles });
          });
          resolve(data.map((item: any) => item.id));
        }).catch((err: any) => {
          reject(err)
        })
      })
    },
    fetchGraphique ({ commit }, { body, url }) {
      return new Promise((resolve, reject) => {
        axios.request({
          url: url,
          method: 'post',
          baseURL: process.env.VUE_APP_API_URL,
          headers: {
            Authorization: axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
          },
          data: body
        }).then((response: any) => {
          const data = response.data;
          commit('setGraphique', { id: body.id, graphique: data, displayFields: body.displayFields })
          resolve(body.id)
        }).catch((err: any) => {
          reject(err)
        })
      })
    },
    uploadRecensements ({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios.request({
          url: '/createRecensement',
          method: 'post',
          baseURL: process.env.VUE_APP_API_URL,
          headers: {
            Authorization: axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
          },
          data: body
        }).then((response: any) => {
          resolve(response)
        }).catch((err: any) => {
          reject(err)
        })
      })
    },
    getRecensementUploadStatus ({ commit }, idUpload) {
      return new Promise((resolve, reject) => {
        axios.request({
          url: '/uploadStatus/' + idUpload,
          method: 'get',
          baseURL: process.env.VUE_APP_API_URL,
          headers: {
            Authorization: axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
          }
        }).then((response: any) => {
          resolve(response)
        }).catch((err: any) => {
          reject(err)
        })
      })
    },
    resetRecensements ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.request({
          url: '/recensements',
          method: 'delete',
          baseURL: process.env.VUE_APP_API_URL,
          headers: {
            Authorization: axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
          }
        }).then((response: any) => {
          resolve(response)
        }).catch((err: any) => {
          reject(err)
        })
      })
    }

  },

  mutations: {
    setGraphique (state, { id, graphique, errors, reponsesPossibles }) {
      state.graphique[id] = graphique
      state.graphiqueErrors[id] = errors
      if (reponsesPossibles) {
        state.graphiqueReponsesPossibles[id] = reponsesPossibles
      }
    }
  }
}

export default recensement
