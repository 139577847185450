import { RouteLocationNormalizedLoaded, Router } from 'vue-router';

export class UrlQueryDeserialiser {
  public getArrayFromRoute (route: RouteLocationNormalizedLoaded, arrayName: string, conversionFunction: (val: string) => any = (val: string) => { return val }): Array<any> {
    const arrayToReturn = new Array<any>();
    const arrayFromRoute = route.query[arrayName];
    if (arrayFromRoute !== undefined) {
      const arrayAsString = arrayFromRoute as string;
      const arrayAsStringList = new Array<string>();
      if (arrayAsString.includes(',')) {
        arrayAsString.split(',').forEach(e => {
          arrayAsStringList.push(e);
        })
      } else {
        arrayAsStringList.push(arrayAsString);
      }
      arrayAsStringList.forEach(e => {
        arrayToReturn.push(conversionFunction(e))
      });
    }
    return arrayToReturn
  }

  public async addToUrlArray (routeInfo: {route: RouteLocationNormalizedLoaded, router: Router }, arrayName: string, item: string): Promise<void> {
    return new Promise<void>((resolve) => {
      const arrayFromRoute = this.getArrayFromRoute(routeInfo.route, arrayName);
      arrayFromRoute.push(item);
      this.setUrlArrayFromArray(routeInfo, arrayName, arrayFromRoute).then(() => {
        resolve();
      });
    });
  }

  public async removeToUrlArray (routeInfo: {route: RouteLocationNormalizedLoaded, router: Router }, arrayName: string, item: string): Promise<void> {
    return new Promise<void>((resolve) => {
      let arrayFromRoute = this.getArrayFromRoute(routeInfo.route, arrayName);
      arrayFromRoute = arrayFromRoute.filter((val: string) => item !== val);
      if (arrayFromRoute.length <= 0) {
        this.removeUrlArray(routeInfo, arrayName).then(() => {
          resolve();
        });
      } else {
        this.setUrlArrayFromArray(routeInfo, arrayName, arrayFromRoute).then(() => {
          resolve();
        });
      }
    });
  }

  public async removeArrayToUrlArray (route: RouteLocationNormalizedLoaded, router: Router, arrayName: string[], item: string[]): Promise<void> {
    return new Promise<void>((resolve) => {
      let toChange = {} as any;

      for (let position = 0; position < arrayName.length; position++) {
        let arrayFromRoute = this.getArrayFromRoute(route, arrayName[position]);
        arrayFromRoute = arrayFromRoute.filter((val: string) => item[position] !== val);
        if (arrayFromRoute.length <= 0) {
          toChange = this.removeUrlArrayToChange(arrayName[position], toChange);
        } else {
          toChange = this.setUrlArrayFromArrayToChange(arrayName[position], arrayFromRoute, toChange);
        }
      }

      router.push({ query: { ...route.query, ...toChange } }).then(() => {
        resolve();
      });
    });
  }

  public async removeUrlArray (routeInfo: {route: RouteLocationNormalizedLoaded, router: Router }, arrayName: string): Promise<void> {
    return new Promise<void>((resolve) => {
      const toChange = {} as any;
      toChange[arrayName] = undefined;
      routeInfo.router.push({ query: { ...routeInfo.route.query, ...toChange }, hash: routeInfo.route.hash }).then(() => {
        resolve();
      });
    });
  }

  public async setUrlArrayFromArray (routeInfo: {route: RouteLocationNormalizedLoaded, router: Router }, arrayName: string, targetArray: Array<any>): Promise<void> {
    return new Promise<void>((resolve) => {
      const toChange = {} as any;
      let arraySerialised = '';
      targetArray.forEach((el: any) => {
        arraySerialised += el.toString() + ','
      });
      if (targetArray.length > 0) {
        arraySerialised = arraySerialised.substring(0, arraySerialised.length - 1);
      }
      toChange[arrayName] = arraySerialised;
      if (arraySerialised.length <= 0) {
        this.removeUrlArrayToChange(arrayName, toChange);
      }
      routeInfo.router.push({ query: { ...routeInfo.route.query, ...toChange }, hash: routeInfo.route.hash }).then(() => {
        resolve();
      });
    });
  }

  private removeUrlArrayToChange (arrayName: string, toChange: any) {
    toChange[arrayName] = undefined;
    return toChange;
  }

  public async setMultipleUrlArrayFromMultipleArray (routeInfo: {route: RouteLocationNormalizedLoaded, router: Router }, arrayNamesList: Array<string>, targetsArraysList: Array<Array<any>>): Promise<void> {
    return new Promise<void>((resolve) => {
      const toChange = {} as any;
      for (let x = 0; x < arrayNamesList.length; x++) {
        const arrName = arrayNamesList[x];
        const targetArray = targetsArraysList[x];
        let arraySerialised = '';
        targetArray.forEach((el: any) => {
          arraySerialised += el.toString() + ','
        });
        if (targetArray.length > 0) {
          arraySerialised = arraySerialised.substring(0, arraySerialised.length - 1);
        }
        toChange[arrName] = arraySerialised;
        if (arraySerialised.length <= 0) {
          this.removeUrlArrayToChange(arrName, toChange);
        }
      }
      routeInfo.router.push({ query: { ...routeInfo.route.query, ...toChange }, hash: routeInfo.route.hash }).then(() => {
        resolve();
      });
    });
  }

  private setUrlArrayFromArrayToChange (arrayName: string, targetArray: Array<any>, toChange: any) {
    let arraySerialised = '';
    targetArray.forEach((el: any) => {
      arraySerialised += el.toString() + ','
    });
    if (targetArray.length > 0) {
      arraySerialised = arraySerialised.substring(0, arraySerialised.length - 1);
    }
    toChange[arrayName] = arraySerialised;
    return toChange;
  }
}
