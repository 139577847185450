
import { defineComponent, PropType } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Row from 'primevue/row';
import ColumnGroup from 'primevue/columngroup';
import { DescriptionGraphiqueDTO } from '@/DTO/DescriptionGraphiqueDTO';
import { TypeTableau } from '@/DTO/Tableau/TypeTableau';
import { TableauLogicHelper } from '../../helpers/TableauLogicHelper';

export default defineComponent({
  name: 'Tableau',
  props: {
    data: {
      type: Object,
      required: true
    },
    panelDouble: Boolean,
    graphInfo: {
      type: Object as PropType<DescriptionGraphiqueDTO>,
      required: true
    }
  },
  components: {
    DataTable,
    Column,
    Row,
    ColumnGroup
  },
  data () {
    return {
      tableauLogique: new TableauLogicHelper(this.graphInfo, this.data)
    }
  },
  computed: {
    titreTableau () {
      return this.tableauLogique.titreTableau();
    },
    titrePremiereLigneTableau (): string {
      return this.tableauLogique.titrePremiereLigneTableau();
    },
    estNoirci () {
      return this.graphInfo.tableauType === TypeTableau.noirci;
    }
  },
  methods: {
    setBodyClass () {
      if (this.estNoirci) {
        return 'row-noirci';
      }
      return '';
    },
    removeParentese (str: string): string {
      return typeof str === 'string' ? str.replace(/\(|\)/g, '') : str;
    },
    removePrefix (str: string, prefix: string): string {
      if (str.startsWith(prefix)) {
        return str.slice(prefix.length);
      }
      return str;
    },
    removeHeaderPrefix (str: string): string {
      if (this.graphInfo.tableauColoneFieldCombinaison) {
        const prefixPos = str.indexOf('_');
        if (prefixPos !== -1) {
          return str.substring(0, prefixPos);
        }
        return str;
      }
      return this.removePrefix(this.removePrefix(str, 'total-'), 'pourcentage-');
    },
    getNumberLine (text: string) {
      return this.tableauLogique.getNumberLine(text);
    },
    getFirstLine (text: string | number) {
      return this.tableauLogique.getFirstLine(text);
    },
    getLastLine (text: string | number) {
      return this.tableauLogique.getLastLine(text);
    }
  },
  created () {
    this.tableauLogique.init();
  }
})
