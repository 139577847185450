
import { reactive, defineComponent } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default defineComponent({
  name: 'ResetPassword',
  setup () {
    const state = reactive({
      password: '',
      repassword: ''
    })
    const rules = {
      password: { required },
      repassword: { required }
    }

    const v$ = useVuelidate(rules, state)

    return { v$, state }
  },
  data () {
    return {
      errorMessage: '',
      successMessage: ''
    }
  },
  methods: {
    validateForm () {
      this.errorMessage = '';
      var invalid : boolean = this.v$.password.$invalid === false ? this.v$.repassword.$invalid : this.v$.password.$invalid;
      if (invalid === true) {
        const error = this.v$.password.$errors[0] === undefined ? this.v$.repassword.$errors[0] : this.v$.password.$errors[0];
        if (error.$validator === 'required') {
          this.errorMessage = 'Veuillez remplir tous les champs';
        }
      } else if (this.state.password !== this.state.repassword) {
        invalid = true;
        this.errorMessage = 'Les mots de passe entrés sont différents'
      }

      return !invalid;
    },
    onSubmit () {
      this.successMessage = '';
      this.v$.password.$touch();
      this.v$.repassword.$touch();
      const credentials = { token: this.$route.params.resetToken, newPassword: this.state.password }
      if (!this.validateForm()) return;
      this.$store.dispatch('auth/resetPassword', credentials).then(
        () => {
          this.successMessage = 'Votre mot de passe a été modifié avec succès!';
        },
        () => {
          this.errorMessage = 'Une erreur est survenue lors de l\'envoi de votre requête';
        }
      );
    }
  }
})
