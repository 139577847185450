
import { defineComponent, inject, PropType, Ref } from 'vue'
import graphiques from '../configs/graphiques.json'
import graphItemPaths from '../configs/graphItemPaths.json'
import { ValueTranslator } from '@/traduction/ValueTranslator'
import { DescriptionGraphiqueDTO } from '../DTO/DescriptionGraphiqueDTO'
import { GraphiqueRequestBodyDTO } from '../DTO/GraphiqueRequestBodyDTO'
import { GraphDataConverter } from '../helpers/GraphDataConverter'
import { EndroitSelectionHelper } from '@/helpers/EndroitSelectionHelper'
import RegroupementDeGraphiques from '../components/RegroupementDeGraphiques.vue';
import { MenuSectionItem } from '@/DTO/MenuSectionItem';
import { GraphiqueAGenererInfoDTO } from '../DTO/GraphiqueAGenererInfoDTO';

export default defineComponent({
  name: 'Graphiques',
  props: {
    sections: {
      default: new Array<MenuSectionItem>(),
      type: Array as PropType<Array<MenuSectionItem>>
    },
    translator: {
      type: ValueTranslator,
      required: true
    }
  },
  components: {
    RegroupementDeGraphiques
  },
  setup () {
    const endroitSelectionHelper = inject('endroitSelectionHelper') as Ref<EndroitSelectionHelper>
    return {
      endroitSelectionHelper
    }
  },
  data () {
    return {
      graphParSectionsAGenerer: new Map<MenuSectionItem, Array<GraphiqueAGenererInfoDTO>>(),
      graphDataConverter: new GraphDataConverter(this.translator),
      corruptedEndroitId: new Array<{id: string, name: string}>(),
      tooManyRequestsError: false,
      pretAAfficher: false
    }
  },
  updated () {
    this.$router.push(this.$route)
  },
  mounted () {
    this.setRecensementsData(true);
  },
  methods: {
    getChilds (item: any, layerToGet: number): any[] {
      if (item.enfants !== undefined) {
        if (layerToGet > 1) {
          let newEnfants = new Array<any>();
          item.enfants.forEach((enf: any) => {
            newEnfants = newEnfants.concat(this.getChilds(enf, layerToGet - 1))
          })
          return newEnfants
        }
        return item.enfants
      }
      return []
    },
    btnRefreshPressed () {
      this.setRecensementsData(true);
    },
    btnEndroitPressed () {
      this.setRecensementsData(false);
    },
    getGraphInfoById (graphId: number): DescriptionGraphiqueDTO {
      const graphTrueID = graphiques.findIndex((graph) => graph.id === graphId);
      return { ...JSON.parse(JSON.stringify(graphiques[graphTrueID])) } as DescriptionGraphiqueDTO;
    },
    getAllGraphToGenerateConsideringMultiVilleAndOneVille (graph: DescriptionGraphiqueDTO, nbEndroits: number): Array<GraphiqueAGenererInfoDTO> {
      const graphAGenerer = new Array<GraphiqueAGenererInfoDTO>();
      const estABuild = nbEndroits === 1;
      const graphsUneVille = new Array<DescriptionGraphiqueDTO>();
      graphsUneVille.push(graph);
      if (graph.otherGraphsUneVille) {
        graph.otherGraphsUneVille.forEach(graphUneVilleItemId => {
          graphsUneVille.push(this.getGraphInfoById(graphUneVilleItemId));
        });
      }
      graphsUneVille.forEach(graphItem => {
        let addedOriginal = false;
        if (graphItem.otherGraphsMultiVilles !== undefined && nbEndroits > 0) {
          // On fait un for loop sur le array pour toute les ajouters dans graphdata
          graphItem.otherGraphsMultiVilles.forEach(graphAjoute => {
            if (graphAjoute !== graphItem.id) {
              graphAGenerer.push({ graphInfo: this.getGraphInfoById(graphAjoute), estABuild: nbEndroits > 1 });
            } else {
              addedOriginal = true;
              graphAGenerer.push({ graphInfo: graphItem, estABuild: true });
            }
          });
        }
        if (addedOriginal === false) {
          graphAGenerer.push({ graphInfo: graphItem, estABuild });
        }
      })
      return graphAGenerer;
    },
    setRecensementsData (needRefreshBackend: boolean) {
      // Pour chaque Id dans la liste d'id de graph dans le lien
      this.pretAAfficher = false;
      this.graphParSectionsAGenerer = new Map<MenuSectionItem, Array<GraphiqueAGenererInfoDTO>>()
      const endroitsIds = this.endroitSelectionHelper.getAllEndroitsWithoutConsideringActivationStatus();
      const nbEndroitActive = this.endroitSelectionHelper.getAllSelectedEndroits().length;
      const corruptedEndroits = this.corruptedEndroitId.filter((endroit: {id: string, name: string}) => endroitsIds.includes(endroit.id))
      if (corruptedEndroits.length > 0) {
        corruptedEndroits.forEach((endroit: {id: string, name: string}) => {
          const message = "L'endroit " + endroit.name + ' (' + endroit.id + ") n'est encore pas valide";
          this.$toast.error(message, { timeout: 20000 });
          console.warn(message);
        });
      } else {
        const bodies = new Array<GraphiqueRequestBodyDTO>();
        this.sections.forEach(section => {
          // const graphListForThisSection = new Array<GraphiqueAGenererInfoDTO>();
          section.graphsId.forEach(id => {
            // On regarde si l'id dans le lien existe dans la liste des graph dans graph.config
            const graphToGetId = graphiques.findIndex((graph) => graph.id === id);
            if (graphToGetId !== -1) {
              // On prépare des champs à envoyer dans le back end et pour le traitement dans le front end
              // Back end et front end
              const graph : DescriptionGraphiqueDTO = { ...JSON.parse(JSON.stringify(graphiques[graphToGetId])) } as DescriptionGraphiqueDTO;
              // Rajoute un array de graphique a generer en fonction du multi ville ou non
              const graphAGenerer = this.getAllGraphToGenerateConsideringMultiVilleAndOneVille(graph, nbEndroitActive);

              // graphListForThisSection.push(...)
              const newSousSection = { nom: graph.name, graphsId: graphAGenerer.map(item => item.graphInfo.id), originalId: graph.id } as MenuSectionItem
              this.graphParSectionsAGenerer.set(newSousSection, graphAGenerer);
              graphAGenerer.forEach(graph => {
                if (needRefreshBackend) {
                  let body = new GraphiqueRequestBodyDTO();
                  if (graph.graphInfo.generatedName) {
                    body = { id: graph.graphInfo.id, generatedName: graph.graphInfo.generatedName, graphName: graph.graphInfo.name, displayFields: graph.graphInfo.displayFields, endroitIds: endroitsIds, includeEndroit: true, percentageType: graph.graphInfo.percentageType };
                  } else {
                    const graphItemsPath = graphItemPaths[graphItemPaths.findIndex((graphPath) => graphPath.graphId === graph.graphInfo.id)].itemsPaths;
                    body = { id: graph.graphInfo.id, itemsPaths: graphItemsPath, displayFields: graph.graphInfo.displayFields, graphName: graph.graphInfo.name, layerToGetInfo: graph.graphInfo.layerToGetInfo, endroitIds: endroitsIds, includeEndroit: true, percentageType: graph.graphInfo.percentageType }
                  }
                  bodies.push(body);
                } else {
                  // si l'Utilisateur désactive manuellement une municipalité en cliquant sur le rectangle bleu poudre, dans l'encadré "Municipalités recherchées"
                  const corruptedEndroits = this.corruptedEndroitId.filter((endroit: {id: string, name: string}) => endroitsIds.includes(endroit.id))
                  if (corruptedEndroits.length !== 0) {
                    corruptedEndroits.forEach((endroit: {id: string, name: string}) => {
                      const message = "L'endroit " + endroit.name + ' (' + endroit.id + ") n'est encore pas valide";
                      this.$toast.error(message, { timeout: 20000 });
                      console.warn(message);
                    });
                  }
                }
              });
            } else {
              // Si l'id du graphique dans l'url n'existe pas
              this.$toast.error("Le graphique avec l'id " + id + " n'existe pas");
              console.warn("Le graphique avec l'id " + id + " n'existe pas");
            }
          })
        })

        // pour chercher les infos juste une fois
        if (needRefreshBackend) {
          const requestBody = { graphList: bodies };
          this.$store.dispatch('recensement/fetchAllGraphics', { requestBody }).then((idGraphList) => {
            // compteur d'itérations
            // nbDoneFetch++;

            // if (idsOfGraphsToBuild.includes(idGraph)) {
            //   generateGraphFunctionList.push(() => this.updateNewDataFrontEnd(graph));
            // }
            // console.log(idGraphList)
            // idGraphList.forEach((id: any) => {
            //   const graphToGetId = graphiques.findIndex((graph) => graph.id === id);
            //   const graph : DescriptionGraphiqueDTO = { ...JSON.parse(JSON.stringify(graphiques[graphToGetId])) } as DescriptionGraphiqueDTO;
            //   this.updateNewDataFrontEnd(graph);
            // });

            // // on veut que cela s'exécute quand tout est revenu du back-end; compte le nb de fois que cette fonction est appelée, puis génère le HTML dans toute la page
            // this.executeIfReady(nbFetchNeeded, nbDoneFetch, functionToDoAfterFinishedLoading);
            this.pretAAfficher = true;
          }).catch((err: any) => {
            const { message, path, endroit } = err.response.data as { message: string, path: Array<string>, endroit: { id: string, name: string } };
            if (path !== undefined) {
              this.$toast.error(message + '\npath: ' + path.join(', '), { timeout: 20000 });
              console.warn(message, path, endroit);
              this.corruptedEndroitId.push(endroit)
            } else if (err.response.status === 429 && !this.tooManyRequestsError) {
              this.tooManyRequestsError = true;
              this.$toast.error('Trop de requêtes ont été faites, veuillez attendre pendant une minute', { timeout: 60000, onClose: () => { this.tooManyRequestsError = false } });
              console.warn('Too many requests');
            }
          })
        } else {
          this.pretAAfficher = true;
        }
      }
    }
  }
})
