
import { defineComponent } from 'vue'
import RadioButton from 'primevue/radiobutton';

export default defineComponent({
  name: 'InputGrilleRadioMaison',
  components: {
    RadioButton
  },
  data () {
    return {
      errorMessage: '',
      value: {} as any,
      reponsesPossibles: new Set<string>()
    }
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    questionProp: {
      type: Object,
      required: true
    }
  },
  emits: ['update:modelValue'],
  created () {
    type repType = { reponsesPossibles: { texteReponse: string }[] };
    for (const sousQuestion of Object.values(this.questionProp.sousQuestions) as repType[]) {
      sousQuestion.reponsesPossibles.forEach(reponse => {
        this.reponsesPossibles.add(reponse.texteReponse)
      })
    }
  },
  methods: {
    onValueChange (sousQuestion: { question: string, reponseChoisie: any }) {
      sousQuestion.reponseChoisie = this.value[sousQuestion.question];
      this.$emit('update:modelValue', this.value);
    },
    validate () {
      // conversion de l'objet this.value en array pour ensuite en récupérer la longueur
      const arrReponsesGrille = Object.keys(this.value);
      const nbReponsesFournies = arrReponsesGrille.length;

      // si la longueur de l'array this.value est plus petite que celle de this.questionProp.sousQuestions.length, c'est qu'on n'a pas répondu à certaines questions.
      if (nbReponsesFournies < this.questionProp.sousQuestions.length) {
        this.errorMessage = 'Une ou plusieurs réponses manquantes.';
      } else {
        // On s'assure de vider la variable de message d'erreur s'il n'y a pas/plus d'erreur. Autrement, après une première erreur de la part de l'utilisateur, le message d'erreur ne partira jamais.
        this.errorMessage = '';
      }
    }
  }
})
