
import { defineComponent } from 'vue'
import RadioButton from 'primevue/radiobutton';

export default defineComponent({
  name: 'InputRadioMaison',
  components: {
    RadioButton
  },
  data () {
    return {
      errorMessage: '',
      value: []
    }
  },
  props: {
    modelValue: {
      type: String,
      required: true
    },
    questionProp: {
      type: Object,
      required: true
    }
  },
  emits: ['update:modelValue'],
  methods: {
    onValueChange () {
      this.$emit('update:modelValue', this.value);
    },
    validate () {
      // Si la longueur de l'array this.value est de zéro, c'est qu'aucune réponse n'a été fournie.
      if (this.value.length === 0) {
        this.errorMessage = 'Réponse manquante.';
      } else {
        // On s'assure de vider la variable de message d'erreur s'il n'y a pas/plus d'erreur. Autrement, après une première erreur de la part de l'utilisateur, le message d'erreur ne partira jamais.
        this.errorMessage = '';
      }
    }
  }
})
