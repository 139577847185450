
import axios from 'axios'
import { Module } from 'vuex'
const questionnaire: Module<any, any> = {
  namespaced: true,
  state: {
  },
  actions: {
    getEndroitRepondant ({ commit }, questionnaireIndex) {
      return new Promise((resolve, reject) => {
        const url = process.env.VUE_APP_API_URL + '/getEndroitRepondant';
        axios
          .post(url, questionnaireIndex)
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    getQuestionnaire ({ commit }, questionnaireName) {
      return new Promise((resolve, reject) => {
        const url = process.env.VUE_APP_API_URL + '/getQuestionnaire';
        axios
          .post(url, questionnaireName)
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    saveQuestionnaire ({ commit }, questionnaire) {
      return new Promise((resolve, reject) => {
        const url = process.env.VUE_APP_API_URL + '/saveQuestionnaire';
        axios
          .post(url, questionnaire)
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    }
  }
}

export default questionnaire
