
import { defineComponent } from 'vue'
import ProgressSpinner from 'primevue/progressspinner';

export default defineComponent({
  name: 'Loading',
  components: {
    ProgressSpinner
  }
})
