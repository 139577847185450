
import menu from '@/configs/Menu.json'
import GlobalParamsHandlers from '../globalParams/GlobalParamsHandlers'
import { PropType, defineComponent } from 'vue'
import { MenuSectionItem } from '@/DTO/MenuSectionItem'

export default defineComponent({
  name: 'Sidebar',
  data () {
    return {
      ongletsTableau: new Array<HTMLElement>(),
      ongletsSousTableau: new Array<HTMLElement>()
    }
  },
  computed: {
    menuComputed () {
      const routeHash = this.$route.hash
      return menu.map((item) => {
        if (item.items !== undefined) {
          item.items = item.items.map((subItem: any) => {
            subItem.isActive = ('#' + subItem.to.params.idListData.toString()) === routeHash
            return subItem
          })
        }
        return item
      })
    }
  },
  methods: {
    addOnglets (node: HTMLElement) {
      if (node) {
        this.ongletsTableau.push(node);
      }
    },
    addSousOnglets (node: HTMLElement) {
      if (node) {
        this.ongletsSousTableau.push(node);
      }
    },
    gereClicOnglet (event: MouseEvent) {
      // variables
      const conteneurOngletsSousOngletsActif = (event.target as HTMLElement).closest('.conteneur-onglets-sous-onglets') as HTMLElement;

      // boucle sur chacun des enfants du conteneur d'onglets et de sous-onglets
      for (const onglet of conteneurOngletsSousOngletsActif.children) {
        const chevron = conteneurOngletsSousOngletsActif.firstElementChild!.firstElementChild!.lastElementChild;

        // affichage/masquage seulement avec sous-onglets, pas l'onglet principal
        if (onglet.classList.contains('onglet') === false) {
          // affichage des sous-onglets
          if (onglet.classList.contains('plie')) {
            chevron!.classList.remove('pi-chevron-right');
            chevron!.classList.add('pi-chevron-down');
            onglet.classList.remove('plie');
            /**
             * ajout/retrait d'une classe quand on plie/déplie les sous-onglets
             * l'onglet n'est pas vraiment actif, mais il en a le  style
             */
            onglet.parentElement!.classList.add('onglet-pseudo-actif');
          // masquage des sous-onglets
          } else {
            chevron!.classList.add('pi-chevron-right');
            chevron!.classList.remove('pi-chevron-down');
            onglet.classList.add('plie')
            /**
             * ajout/retrait d'une classe quand on plie/déplie les sous-onglets
             * l'onglet n'est pas vraiment actif, mais il en a le  style
             */
            onglet.parentElement!.classList.remove('onglet-pseudo-actif');
          }
        }
      }
      // fin du bloc
    },
    afficheCacheOnglets () {
      // on commence par cacher tous les sous-onglets
      for (const item of this.ongletsSousTableau) {
        item.classList.add('plie');
      }
      // fin de la boucle

      // on affiche ensuite seulement ce qui doit l'être (actif)
      for (const sousOnglet of this.ongletsSousTableau) {
        // variables temporaires
        const parent = sousOnglet.parentElement;
        const chevron = sousOnglet.parentElement!.firstElementChild!.firstElementChild!.lastElementChild;

        // pas question de faire break ici, quand chaque sous-onglet est à déplier un à un
        if (parent!.classList.contains('onglet-actif')) {
          sousOnglet.classList.remove('plie');
          chevron!.classList.remove('pi-chevron-right');
          chevron!.classList.add('pi-chevron-down');
        }
      }
      // fin de la boucle
    },
    changeCouleursOnglets () {
      // vérifications sur les onglets
      for (const item of this.ongletsTableau) {
        // vérifie présence class active sur onglets principaux
        const estActifOngletSeul = item.firstElementChild!.firstElementChild!.classList[0] === 'router-link-active';

        // style de l'onglet quand il est actif
        if (estActifOngletSeul) {
          item.classList.add('onglet-actif');

          // On Profite de l'occasion pour récupérer le titre des onglets principaux quand il n'y a pas de sous-onglets. Cas avec sous-onglets ci-dessous.
          GlobalParamsHandlers.pageTitle = item.innerText;

          // il ne peut y avoir qu'un seul lien actif, donc fin de la boucle
          break;
        }
      }

      // vérifications sur les sous-onglets
      for (const item in this.ongletsSousTableau) {
        // vérifie présence de la classe active sur le lien autour du sous-onglet
        const sontActifsSousOnglets = this.ongletsSousTableau[item]!.closest('div')!.firstElementChild!.classList[0] === 'router-link-active';

        // style de l'onglet quand sous-onglet actif; le style du sous-onglet se fait par le CSS
        if (sontActifsSousOnglets) {
          this.ongletsSousTableau[item].parentElement!.classList.add('onglet-actif');
          // il ne peut y avoir qu'un seul lien actif

          GlobalParamsHandlers.pageTitle = (this.ongletsSousTableau[item].parentElement!.children[0] as HTMLElement).innerText;
          break;
        }
      }
    },
    // pour ouvrir menu latéral
    openSideNavButton () {
      const menuLateral = this.$refs.menuLateral as HTMLElement;
      menuLateral.style.width = '278px';
    },
    // pour fermer menu latéral
    closeSideNavButton () {
      const menuLateral = this.$refs.menuLateral as HTMLElement;
      menuLateral.style.width = '0';
    },
    /**
     * Si l'utilisateur s'amuse à redimensionner manuellement la taille de son navigateur, le menu de navigation latérale détermine lui-même s'il doit s'afficher ou non.
     *
     * Sert à éviter un bogue où le menu latéral se retrouvait avec une largeur de 0 en mode desktop si l'utilisateur avait décidé de le cacher en mode mobile avant de repassser en mode desktop.
     *
     * Important! Si on change la largeur de 760 ci-dessous, il faut aussi changer ce nombre dans le CSS (media queries), et surtout faire certains tests pour s'assurer qu'il n'y a pas un fossé/intervalle où le JS et le CSS ne sont pas pris en compte.
     *
     * Pour déboguer cette fonction, il peut être utile d'utiliser ces lignes de code.
     *
     * console.log(`clientWidth: ${document.documentElement.clientWidth}`);
     * console.log(`innerWidth: ${window.innerWidth}`);
     * console.log('===');
     *
     * clientWidth = sans la largeur de la barre de défilement
     * innerWidth = avec la largeur de la barre de défilement
     *
     */
    getDimensions () {
      const menuLateral = this.$refs.menuLateral as HTMLElement;

      // en mode desktop, on force une largeur de 278
      if (window.innerWidth >= 760) {
        menuLateral.style.width = '278px';
      // autrement, il faut cliquer sur le bouton du menu latéral pour l'afficher
      } else {
        menuLateral.style.width = '0';
      }
    }
  },
  props: {
    sectionsAffiche: {
      type: Array as PropType<Array<MenuSectionItem>>,
      required: true
    }
  },
  mounted () {
    // Fix pour le load du menu
    this.changeCouleursOnglets();
    this.afficheCacheOnglets();
    window.addEventListener('resize', this.getDimensions);
  },
  unmounted () {
    // ce n'est pas strictement nécessaire de retirer le gestionnaire, mais ce serait une bonne pratique
    // https://masteringjs.io/tutorials/vue/resize-event
    window.removeEventListener('resize', this.getDimensions);
  }
});
