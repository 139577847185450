
import { defineComponent, inject, PropType, Ref } from 'vue'
import { EndroitDTO } from '../DTO/EndroitDTO'
import { EndroitSelectionHelper } from '@/helpers/EndroitSelectionHelper';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import Dialog from 'primevue/dialog';
import { EndroitType } from '@/DTO/EndroitType';
// import { array } from '@amcharts/amcharts4/core';
export default defineComponent({
  name: 'RechercheEndroitCriteres',
  emits: ['onEndroitUpdated'],
  components: {
    InputNumber,
    Dropdown,
    Dialog,
    Checkbox
  },
  props: {
    endroitsData: {
      type: Array as PropType<Array<EndroitDTO>>,
      required: true
    }
  },
  setup () {
    const endroits = inject('endroits') as Ref<Array<EndroitDTO>>
    const endroitSelectionHelper = inject('endroitSelectionHelper') as Ref<EndroitSelectionHelper>
    return {
      endroits,
      endroitSelectionHelper
    }
  },
  data () {
    return {
      selectionneAll: false,
      showResultatsRecherche: false,
      btnRechercheEnabled: false,
      resultatsRecherche: new Array<EndroitDTO>(),
      criteres: [
        {
          nom: "Nombre d'habitants",
          champ: 'populationTotal',
          unite: 'hab.',
          type: 'range',
          active: false,
          value: {
            min: 0,
            max: 0
          }
        },
        {
          nom: 'Dépenses loisir',
          champ: 'depenseLoisirEtCulture',
          unite: '$',
          type: 'range',
          active: false,
          value: {
            min: 0,
            max: 0
          }
        },
        {
          nom: 'Dépenses totales',
          champ: 'depenseTotal',
          unite: '$',
          type: 'range',
          active: false,
          value: {
            min: 0,
            max: 0
          }
        },
        {
          nom: 'Superficie en km2',
          champ: 'superficieKm2',
          unite: 'km2',
          type: 'range',
          active: false,
          value: {
            min: 0,
            max: 0
          }
        },
        {
          nom: 'Densité en km2',
          champ: 'densiteKm2',
          unite: 'hab./km2',
          type: 'range',
          active: false,
          value: {
            min: 0,
            max: 0
          }
        },
        {
          nom: 'Richesse foncière uniformisé (RFU)',
          champ: 'rfu',
          unite: '$',
          type: 'range',
          active: false,
          value: {
            min: 0,
            max: 0
          }
        },
        {
          nom: 'Endettement à long terme par 100$ (RFU)',
          champ: 'endettement100rfu',
          unite: '',
          type: 'range',
          active: false,
          value: {
            min: 0,
            max: 0
          }
        },
        {
          nom: 'Charges municipal par 100$ (RFU)',
          champ: 'charge100rfu',
          unite: '',
          type: 'range',
          active: false,
          value: {
            min: 0,
            max: 0
          }
        },
        {
          nom: 'Taux de faible revenu',
          champ: 'tauxFaibleRevenue',
          unite: '%',
          type: 'range',
          active: false,
          value: {
            min: 0,
            max: 0
          }
        },
        {
          nom: 'Taux global de taxation',
          champ: 'tauxGlobalTaxation',
          unite: '',
          type: 'range',
          active: false,
          value: {
            min: 0,
            max: 0
          }
        },
        {
          nom: 'Pourcentage de dépense en loisir et culture',
          champ: 'pourcentageDepenseLoisirEtCulture',
          unite: '%',
          type: 'range',
          active: false,
          value: {
            min: 0,
            max: 0
          }
        },
        {
          nom: 'Poste budgétaire en loisir le plus élevé',
          champ: 'postePlusEleve',
          unite: '',
          type: 'select',
          active: false,
          value: {
            selected: null,
            min: 0,
            max: 0
          },
          options: [
            {
              value: 'p6047',
              label: 'Centres communautaires'
            },
            {
              value: 'p6048',
              label: 'Patinoires intérieures et extérieures'
            },
            {
              value: 'p6049',
              label: 'Piscines, plages et ports de plaisance'
            },
            {
              value: 'p6050',
              label: 'Parcs et terrains de jeux'
            },
            {
              value: 'p6071',
              label: 'Parcs régionaux'
            },
            {
              value: 'p6051',
              label: 'Expositions et foires'
            },
            {
              value: 'p6052',
              label: 'Autres activités récréatives'
            },
            {
              value: 'p6054',
              label: 'Centres communautaires (activité récréative)'
            },
            {
              value: 'p6055',
              label: 'Bibliothèques'
            },
            {
              value: 'p6056',
              label: 'Musées et centres d\'exposition'
            },
            {
              value: 'p6057',
              label: 'Autres ressources du patrimoine'
            },
            {
              value: 'p6058',
              label: 'Autres activités culturelles'
            }
          ]
        },
        {
          nom: 'Entente scolaire',
          champ: 'quest1EntenteScolaire',
          type: 'select',
          unite: '',
          isQuestionnaire: true,
          active: false,
          value: {
            selected: null,
            min: 0,
            max: 0
          },
          options: [
            {
              value: 'Oui',
              label: 'Oui'
            },
            {
              value: 'Non',
              label: 'Non'
            }
          ]
        },
        {
          nom: 'Village relais',
          champ: 'quest1VillageRelais',
          type: 'select',
          unite: '',
          isQuestionnaire: true,
          active: false,
          value: {
            selected: null,
            min: 0,
            max: 0
          },
          options: [
            {
              value: 'Oui',
              label: 'Oui'
            },
            {
              value: 'Non',
              label: 'Non'
            }
          ]
        },
        {
          nom: 'Consultation publique récente (moins de 3 ans)',
          champ: 'quest1ConsultationPublique',
          type: 'select',
          unite: '',
          isQuestionnaire: true,
          active: false,
          value: {
            selected: null,
            min: 0,
            max: 0
          },
          options: [
            {
              value: 'Oui',
              label: 'Oui'
            },
            {
              value: 'Non',
              label: 'Non'
            }
          ]
        },
        {
          nom: 'Entente intermunicipal',
          champ: 'quest1EntenteIntermunicipal',
          type: 'select',
          unite: '',
          isQuestionnaire: true,
          active: false,
          value: {
            selected: null,
            min: 0,
            max: 0
          },
          options: [
            {
              value: 'Oui',
              label: 'Oui'
            },
            {
              value: 'Non',
              label: 'Non'
            }
          ]
        },
        {
          nom: 'Comité loisir',
          champ: 'quest1ComiteLoisir',
          type: 'select',
          unite: '',
          isQuestionnaire: true,
          active: false,
          value: {
            selected: null,
            min: 0,
            max: 0
          },
          options: [
            {
              value: 'Oui',
              label: 'Oui'
            },
            {
              value: 'Non',
              label: 'Non'
            }
          ]
        },
        {
          nom: 'Système d\'inscription en ligne',
          champ: 'quest1SystemeInscription',
          type: 'select',
          unite: '',
          isQuestionnaire: true,
          active: false,
          value: {
            selected: null,
            min: 0,
            max: 0
          },
          options: [
            {
              value: 'Oui',
              label: 'Oui'
            },
            {
              value: 'Non',
              label: 'Non'
            }
          ]
        },
        {
          nom: 'Infastructure majeure',
          champ: 'quest1InfastructureMajeure',
          type: 'select',
          unite: '',
          isQuestionnaire: true,
          active: false,
          value: {
            selected: null,
            min: 0,
            max: 0
          },
          options: [
            {
              value: 'Oui',
              label: 'Oui'
            },
            {
              value: 'Non',
              label: 'Non'
            }
          ]
        }
        // {
        //   nom: 'Nombre employé en loisir',
        //   champ: 'nombreEmployeLoisir',
        //   unite: '',
        //   type: 'range',
        //   active: false,
        //   value: {
        //     min: 0,
        //     max: 0
        //   }
        // }
      ]
    }
  },
  computed: {
    critereActive: function () {
      return this.criteres.filter(x => x.active === true);
    }
  },
  methods: {
    clickInput (crit:any) {
      crit.active = true;
      this.updateBtnRecherche();
    },
    getEndroitCritere (endroit:EndroitDTO, crit:any) {
      let value = endroit[crit.champ];
      if (crit.champ === 'populationTotal' || crit.champ === 'depenseLoisirEtCulture' || crit.champ === 'depenseTotal' || crit.champ === 'rfu') {
        value = endroit[crit.champ].toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ' ');
      }
      if (crit.champ === 'pourcentageDepenseLoisirEtCulture' || crit.champ === 'tauxFaibleRevenue') {
        value = parseFloat(endroit[crit.champ]).toFixed(1);
      }

      if (crit.champ === 'endettement100rfu' || crit.champ === 'charge100rfu') {
        value = (parseFloat(endroit[crit.champ]) * 100).toFixed(2);
      }

      if (crit.champ === 'tauxGlobalTaxation') {
        value = (parseFloat(endroit[crit.champ])).toFixed(2);
      }
      if (crit.type === 'select') {
        if (crit.isQuestionnaire === true) {
          value = value.reponse;
          // value = crit.options.find((element:any) => element.value.reponse === crit.value.selected);
        } else {
          value = crit.options.find((element:any) => element.value === crit.value.selected).label;
        }
      }

      if (value === undefined) {
        return 'N/A';
      } else {
        return value + ' ' + crit.unite;
      }
    },
    updateBtnRecherche () {
      this.btnRechercheEnabled = this.criteres.some(crit => crit.active)
    },
    cancelSelectionneAll () {
      this.selectionneAll = false;
    },
    selectionnerEndroits () {
      this.endroitSelectionHelper.addMultipleEndroits(this.resultatsRecherche.filter(endroit => endroit.selectionne).map(end => end._id)).then(() => {
        this.$toast.success('Les endroits ont bien été ajoutés.');
        this.$emit('onEndroitUpdated');
        this.showResultatsRecherche = false;
      });
    },
    selectionnerAllEndroits () {
      this.selectionneAll = !this.selectionneAll;
      this.resultatsRecherche.forEach(element => {
        element.selectionne = !element.selectionne;
      });
    },
    rechercherCriteres () {
      // const criteres: { [key: string]: any } = {};
      // this.criteres.forEach(crit => {
      //   if (crit.type === 'range') {
      //     criteres[crit.champ + '_min'] = crit.value.min;
      //     criteres[crit.champ + '_max'] = crit.value.max;
      //   } else {
      //     criteres[crit.champ] = crit.value;
      //   }
      // })
      this.showResultatsRecherche = true;
      this.resultatsRecherche = this.endroitsData.filter((endroit) => {
        return endroit.typeEndroit === EndroitType.municipalite && this.criteres.every(crit => {
          if (!crit.active) {
            return true;
          } else if (crit.champ in endroit) {
            const valeur = (endroit as any)[crit.champ];
            if (crit.type === 'range' && valeur > 0) {
              return (valeur >= crit.value.min) && ((valeur <= crit.value.max) || (crit.value.min !== 0 && crit.value.max === 0));
            } else if (crit.type === 'select') {
              if (crit.isQuestionnaire) {
                return (valeur.reponse === crit.value.selected);
              } else {
                return (valeur === crit.value.selected);
              }
            }
          }
          return false;
        })
      }).map((endroit) => {
        return { ...endroit, selectionne: false };
      });
      this.resultatsRecherche.sort((a, b) => {
        if (a.NOM_GEO === b.NOM_GEO) {
          return 0;
        } else if (a.NOM_GEO < b.NOM_GEO) {
          return -1;
        } else {
          return 1;
        }
      });
      // this.$store.dispatch('endroit/getEndroitsByCriteres', criteres).then((result) => {
      // })
    }
  }
})

