
import { EndroitDTO } from '@/DTO/EndroitDTO'
import { defineComponent, PropType } from 'vue'
import RechercheEndroitCarte from '../components/RechercheEndroitCarte.vue'
import RechercheEndroitTexte from '@/components/RechercheEndroitTexte.vue'
import RechercheEndroitCriteres from '@/components/RechercheEndroitCriteres.vue'
import RechercheEndroitTexteUneBoite from '@/components/RechercheEndroitTexteUneBoite.vue'
import RechercheEndroitParRepondant from '@/components/RechercheEndroitParRepondant.vue'
export default defineComponent({
  name: 'Recherche',
  props: {
    allEndroitsData: {
      type: Array,
      required: true
    }
  },
  components: {
    RechercheEndroitCarte,
    RechercheEndroitTexte,
    RechercheEndroitCriteres,
    RechercheEndroitTexteUneBoite,
    RechercheEndroitParRepondant
  },
  methods: {
    onEndroitUpdated () {
      (this.$refs.RechercheEndroitCarte as any).updateEndroitSelected();
    }
  },
  mounted () {
    this.$loading.stopLoading();
  }
})
