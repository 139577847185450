export class GraphiqueRequestBodyDTO {
  public displayFields!: Array<any>;
  public generatedName?: string;
  public graphName!: string;
  public id!: number;
  public includeEndroit?: boolean;
  public itemsPaths?: Array<any>;
  public layerToGetInfo?: number;
  public endroitIds!: Array<string>;
  public percentageType: any;
}
