import { createStore } from 'vuex'
import auth from '@/store/auth'
import recensement from '@/store/recensement'
import endroit from '@/store/endroit'
import formulaires from '@/store/formulaires'
import questionnaire from '@/store/questionnaire'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    recensement,
    endroit,
    formulaires,
    questionnaire
  }
})
