
import { reactive, defineComponent } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';

export default defineComponent({
  name: 'Forgot-Password',
  setup () {
    const state = reactive({
      email: ''
    })
    const rules = {
      email: { required, email }
    }

    const v$ = useVuelidate(rules, state)

    return { v$, state }
  },
  data () {
    return {
      errorMessage: '',
      successMessage: ''
    }
  },
  methods: {
    validateForm () {
      this.errorMessage = '';
      const invalid: boolean = this.v$.email.$invalid;
      if (invalid === true) {
        const error = this.v$.email.$errors[0];
        if (error.$validator === 'required') {
          this.errorMessage = 'L\'adresse courriel est requise';
        } else if (error.$validator === 'email') {
          this.errorMessage = 'L\'adresse courriel est invalide';
        }
      }

      return !invalid;
    },
    onSubmit () {
      this.successMessage = '';
      this.v$.email.$touch();
      const credentials = { email: this.state.email }
      if (!this.validateForm()) return;
      this.$store.dispatch('auth/requestResetPassword', credentials).then(
        () => {
          this.successMessage = 'Un courriel de réinitialisation de mot de passe a été envoyé à votre adresse courriel';
        },
        () => {
          this.errorMessage = 'Une erreur est survenue lors de l\'envoi de votre requête';
        }
      );
    }
  }

})
