import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c969c49"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "errors"
}
const _hoisted_2 = {
  key: 0,
  class: "errors-item"
}
const _hoisted_3 = { class: "errors-item-message" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "errors-item-dev"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.erreursList.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.erreursList, (error) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: error }, [
            (_ctx.selectedEndroit.includes(error.endroitData._id))
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("span", null, _toDisplayString(error.endroitData.NOM_GEO) + ": " + _toDisplayString(error.errorType), 1),
                    _createElementVNode("div", {
                      class: "errors-item-message-details",
                      onClick: ($event: any) => (_ctx.toggleDetails(error))
                    }, _toDisplayString(error.showDetails.value ? 'Cacher' : 'Voir') + " les détails ", 9, _hoisted_4)
                  ]),
                  (error.showDetails.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        (error.path)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_6, "path: " + _toDisplayString(error.path), 1))
                          : _createCommentVNode("", true),
                        (error.theme)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_7, "theme: " + _toDisplayString(error.theme), 1))
                          : _createCommentVNode("", true),
                        (error.annee)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_8, "annee: " + _toDisplayString(error.annee), 1))
                          : _createCommentVNode("", true),
                        (error.value)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_9, "value: " + _toDisplayString(error.value), 1))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}