import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8494cd84"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RegroupementDeGraphiques = _resolveComponent("RegroupementDeGraphiques")!

  return (_ctx.pretAAfficher)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.graphParSectionsAGenerer, ([section, dataItem]) => {
          return (_openBlock(), _createElementBlock("div", { key: section }, [
            _createVNode(_component_RegroupementDeGraphiques, {
              section: section,
              endroitSelectionHelper: _ctx.endroitSelectionHelper,
              graphDataConverter: _ctx.graphDataConverter,
              graphDataList: dataItem,
              translator: _ctx.translator
            }, null, 8, ["section", "endroitSelectionHelper", "graphDataConverter", "graphDataList", "translator"])
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}