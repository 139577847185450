import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "customNumberResponse" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputNumber = _resolveComponent("InputNumber")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_InputNumber, {
      min: 0,
      max: 9999,
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      onInput: _ctx.onValueChange,
      buttonLayout: "horizontal",
      decrementButtonClass: "p-button-secondary",
      decrementButtonIcon: "pi pi-minus",
      incrementButtonClass: "p-button-secondary",
      incrementButtonIcon: "pi pi-plus",
      showButtons: ""
    }, null, 8, ["modelValue", "onInput"])
  ]))
}