
import { defineComponent } from 'vue';
import domToImage from 'dom-to-image-more';
import { elementToSVG, inlineResources } from 'dom-to-svg'
import { DescriptionGraphiqueDTO } from '../DTO/DescriptionGraphiqueDTO';
import { TableauLogicHelper } from '@/helpers/TableauLogicHelper';
import { Column, Workbook, Worksheet } from 'exceljs';
import { TableauLigneDTO } from '@/DTO/Tableau/TableauLigneDTO';

export default defineComponent({
  name: 'Exports',
  emits: ['startExport', 'endExport'],
  props: {
    idCanvas: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    graphInfo: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      visible: false
    }
  },
  methods: {
    getTrueData (graphInfo: DescriptionGraphiqueDTO, data: any) {
      const tableauLogique = new TableauLogicHelper(graphInfo, data)
      tableauLogique.init();
      const colonnesArray = Array.of(...tableauLogique.Colonnes).map(colName => {
        return { field: colName, header: colName }
      });
      return { trueData: tableauLogique.TrueData, colonnesArray, tableauLogique };
    },
    getDataInOrder (data: Array<any>) {
      if (!data.some(dataItem => dataItem.colonnes.length !== 1)) {
        data.sort((a, b) => {
          return parseFloat(b.colonnes[0].value) - parseFloat(a.colonnes[0].value);
        })
      }
      return data
    },
    getTopCol (tableauLogique: TableauLogicHelper, graphInfo: DescriptionGraphiqueDTO) {
      let topCol = tableauLogique.ColonnesTop;
      if (graphInfo.tableauGroupeInfo) {
        topCol = [graphInfo.tableauGroupeInfo.nomPourcentage, graphInfo.tableauGroupeInfo.nomTotal];
      }
      return topCol;
    },
    addTitre (sheet: Worksheet, graphInfo: DescriptionGraphiqueDTO, colLength: number) {
      sheet.addRow([graphInfo.name]);
      sheet.mergeCells('A1:' + String.fromCharCode(64 + colLength) + '1');
      sheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
    },
    addTopCols (sheet: Worksheet, graphInfo: DescriptionGraphiqueDTO, topCol: Array<string>, colLength: number) {
      const topHeaderLigneIndex = 2;
      const merges = new Array<string>();
      const startLetters = new Array<string>();
      if (topCol.length > 0) {
        const topRow = new Array<any>();
        if (!graphInfo.tableauHideFirstColumn) {
          topRow.push('');
        }
        const colspan = (colLength - 1) / topCol.length;
        topCol.forEach((colName, index) => {
          topRow.push(colName)
          for (let x = 1; x < colspan; x++) {
            topRow.push('')
          }
          const startIndex = index * colspan + 2;
          const endIndex = startIndex + colspan - 1;
          const startLetter = String.fromCharCode(64 + startIndex);
          const endLetter = String.fromCharCode(64 + endIndex);
          startLetters.push(startLetter);
          merges.push(startLetter + topHeaderLigneIndex + ':' + endLetter + topHeaderLigneIndex);
        })
        sheet.addRow(topRow);
        startLetters.forEach(letter => {
          const cell = sheet.getCell(letter + topHeaderLigneIndex);
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFD9D9D9' }
          }
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        })
        merges.forEach(element => {
          sheet.mergeCells(element);
        });
      }
    },
    addCols (sheet: Worksheet, topCol: Array<string>, columns: Array<{ title: string, field: string } | { header: string, field:string }>) {
      const headerRowIndex = topCol.length > 0 ? '3' : '2';
      sheet.addRow(columns.map(col => {
        if ('title' in col) {
          return this.removeHeaderPrefix(col.title);
        } else {
          return this.removeHeaderPrefix(col.header);
        }
      }));
      for (let x = 1; x <= columns.length; x++) {
        sheet.getCell(String.fromCharCode(64 + x) + headerRowIndex).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFD9D9D9' }
        }
      }
    },
    addData (data: Array<TableauLigneDTO>, sheet: Worksheet, columns: Array<{ title: string, field: string } | { header: string, field:string }>) {
      data.forEach((dataItem) => {
        const rowObject = new Array<any>();
        columns.forEach(col => {
          let rowValue = dataItem[col.field];
          if (!isNaN(rowValue)) {
            rowValue = parseFloat(rowValue);
          } else {
            var correspondance = rowValue.match(/\d{1,3}(?: \d{3})*(?:\.\d+)?/);
            if (correspondance != null) {
              var premierNombre = correspondance ? parseFloat(correspondance[0].replace(/ /g, '').replace(',', '.')) : null;
              rowValue = premierNombre;
            }
          }
          rowObject.push(rowValue);
        })
        const row = sheet.addRow(rowObject);
        row.eachCell((cell) => {
          cell.alignment = { vertical: 'middle', horizontal: 'right' };
        })
      })
    },
    setBordersToCells (sheet: Worksheet) {
      for (let c = 1; c <= sheet.columns.length; c++) {
        for (let r = 1; r <= sheet.rowCount; r += 1) {
          const cell = sheet.getCell(r, c);
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        }
      }
    },
    setColsWidth (sheet: Worksheet) {
      for (let i = 0; i < sheet.columns.length; i++) {
        let dataMax = 0;
        const column = sheet.columns[i] as Column;
        for (let j = 2; j < column.values.length; j += 1) {
          const colValue = column.values[j];
          if (colValue !== undefined && colValue !== null) {
            const columnLength = typeof colValue === 'string' ? colValue.length : 10;
            if (columnLength > dataMax) {
              dataMax = columnLength;
            }
          }
        }
        column.width = dataMax < 10 ? 10 : dataMax;
      }
    },
    downloadExcel (workbook: Workbook) {
      workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], { type: 'application/xlsx' });
        const downloadElement = document.createElement('a');
        const href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = 'export.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    },
    exporterExcel (graphInfo: DescriptionGraphiqueDTO, data: any) {
      const { trueData, colonnesArray, tableauLogique } = this.getTrueData(graphInfo, data)
      const columns = graphInfo.tableauHideFirstColumn ? colonnesArray : [{ field: 'valeurPremiereColonne', title: tableauLogique.titrePremiereLigneTableau() }, ...colonnesArray];
      const topCol = this.getTopCol(tableauLogique, graphInfo);
      const workbook = new Workbook();
      const sheet = workbook.addWorksheet('Donnees');

      this.addTitre(sheet, graphInfo, columns.length); // ajoute le titre du graphique en haut du tableau
      this.addTopCols(sheet, graphInfo, topCol, columns.length); // ajoute les noms des catégories de colonnes en haut du tableau
      this.addCols(sheet, topCol, columns); // ajoute les noms des colonnes en haut du tableau
      this.addData(trueData, sheet, columns); // ajoute les données du tableau
      this.setBordersToCells(sheet); // ajoute les bordures aux cellules
      this.setColsWidth(sheet); // ajuste la largeur des colonnes
      this.downloadExcel(workbook); // télécharge le fichier excel
    },
    removeHeaderPrefix (str: string): string {
      if (this.graphInfo.tableauColoneFieldCombinaison) {
        const prefixPos = str.indexOf('_');
        if (prefixPos !== -1) {
          return str.substring(0, prefixPos);
        }
        return str;
      }
      return this.removePrefix(this.removePrefix(str, 'total-'), 'pourcentage-');
    },
    removePrefix (str: string, prefix: string): string {
      if (str.startsWith(prefix)) {
        return str.slice(prefix.length);
      }
      return str;
    },
    /**
     * Premier paramètre: élément HTML.
     * Deuxième paramètre: "block" ou "none" (valeur de "display").
     */
    afficheCacheSourceTableau (elementHTML: HTMLElement, nouvelEtat: string) {
      if (elementHTML !== null) {
        elementHTML.style.display = nouvelEtat;
      }
    },
    exporterPNG () {
      // pour afficher la source au moment de l'exportation
      this.$emit('startExport');
      const elements = document.querySelectorAll<HTMLElement>('.p-datatable-responsive-scroll > .p-datatable-wrapper');
      for (var i = 0; i < elements?.length; i++) {
        const element = elements[i];
        element.style.overflow = 'hidden'
      }
      const node = this.$refs.contenuAExporter as HTMLElement;
      // const node = this.$refs[reference] as Node;
      // const node = this.$refs.exportTable as Node;

      const scale = 5;
      const style = {
        transform: `scale(${scale})`,
        transformOrigin: 'top left',
        width: node.clientWidth + 'px', // use original width of DOM element to avoid part of the image being cropped out
        height: node.clientHeight + 'px' // use original height of DOM element
      };
      domToImage.toPng(node, {
        width: node.clientWidth * scale,
        height: node.clientHeight * scale,
        style: style
      }).then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'export.png';
        link.href = dataUrl;
        link.click();

        // une fois l'exportation faite, on cache à nouveau le tableau
        for (var i = 0; i < elements?.length; i++) {
          const element = elements[i];
          element.style.overflow = 'auto'
        }
        this.$emit('endExport');
      }).catch(function (err) {
        console.error('oops, something went wrong!', err);
      });
    },

    filter (node: HTMLElement) {
      return (node.tagName !== 'i');
    },

    async sleep (milliseconds: any) {
      return new Promise(resolve => setTimeout(resolve, milliseconds))
    },

    async exporterSVG () {
      // pour afficher la source au moment de l'exportation
      this.$emit('startExport');
      await this.sleep(10);

      const node = this.$refs.contenuAExporter as HTMLElement;

      const iconeTriTableau: any = document.getElementsByClassName('p-sortable-column-icon');
      for (var i = 0; i < iconeTriTableau.length; i++) {
        iconeTriTableau[i].style.visibility = 'hidden';
      }
      const svgDocument = elementToSVG(node);
      // Inline external resources (fonts, images, etc) as data: URIs
      await inlineResources(svgDocument.documentElement)
      // Get SVG string
      var source = new XMLSerializer().serializeToString(svgDocument)

      /*eslint-disable */
      if (!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)){
        source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
      }

      if (!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)){
        source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
      }

      source = '<?xml version="1.0" standalone="no"?>\r\n' + source;
      var url = "data:image/svg+xml;charset=utf-8,"+encodeURIComponent(source);

      /* eslint-enable */
      const link = document.createElement('a');
      link.download = 'export.svg';
      link.href = url;
      link.click();
      for (var j = 0; j < iconeTriTableau.length; j++) {
        iconeTriTableau[j].style.visibility = 'visible';
      }

      // pour afficher la source au moment de l'exportation
      this.$emit('endExport');
    }
  }
})
