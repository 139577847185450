
import { defineComponent } from 'vue'
import InputNumber from 'primevue/inputnumber';

export default defineComponent({
  name: 'InputNumberMaison',
  components: {
    InputNumber
  },
  data () {
    return {
      value: 0
    }
  },
  created () {
    this.value = this.modelValue
  },
  props: {
    modelValue: {
      type: Number,
      required: true
    }
  },
  emits: ['update:modelValue'],
  methods: {
    onValueChange (event: any) {
      this.$emit('update:modelValue', event.value);
    }
  }
})
