import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createBlock(_component_InputText, {
    onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
    pattern: _ctx.generePattern,
    placeholder: _ctx.placeholderProp,
    required: "false",
    type: _ctx.typeProp,
    value: _ctx.modelValue
  }, null, 8, ["pattern", "placeholder", "type", "value"]))
}