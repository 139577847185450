import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "flex graphique_et_tableau" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GraphiqueABande = _resolveComponent("GraphiqueABande")!
  const _component_GraphiqueACourbe = _resolveComponent("GraphiqueACourbe")!
  const _component_GraphiqueATarte = _resolveComponent("GraphiqueATarte")!
  const _component_GraphSources = _resolveComponent("GraphSources")!
  const _component_Exports = _resolveComponent("Exports")!
  const _component_EndroitError = _resolveComponent("EndroitError")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_Tableau = _resolveComponent("Tableau")!
  const _component_TabView = _resolveComponent("TabView")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.section.originalId
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.graphiquesData, (graphiqueDataItem) => {
      return (_openBlock(), _createElementBlock("div", {
        key: graphiqueDataItem,
        id: graphiqueDataItem.graphId,
        class: "panelItemContainer"
      }, [
        _createVNode(_component_TabView, { lazy: true }, {
          default: _withCtx(() => [
            (!graphiqueDataItem.graphInfo.aucunGraph)
              ? (_openBlock(), _createBlock(_component_TabPanel, {
                  key: 0,
                  header: "Graphique"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Exports, {
                      data: graphiqueDataItem.data,
                      graphInfo: graphiqueDataItem.graphInfo,
                      idCanvas: 'graphique-' + graphiqueDataItem.graphInfo.id,
                      id: 'graphique-' + graphiqueDataItem.graphInfo.id,
                      class: "colonne-soit-graphique-soit-tableau"
                    }, {
                      default: _withCtx(() => [
                        (graphiqueDataItem.graphInfo.graphType === 'bande')
                          ? (_openBlock(), _createBlock(_component_GraphiqueABande, {
                              key: 0,
                              graphDataConverter: _ctx.graphDataConverter,
                              graphInfo: graphiqueDataItem.graphInfo,
                              panelDouble: false,
                              graphAxisName: graphiqueDataItem.graphAxisName,
                              data: graphiqueDataItem.data,
                              fieldValue: graphiqueDataItem.fields,
                              translator: _ctx.translator
                            }, null, 8, ["graphDataConverter", "graphInfo", "graphAxisName", "data", "fieldValue", "translator"]))
                          : _createCommentVNode("", true),
                        (graphiqueDataItem.graphInfo.graphType === 'courbe')
                          ? (_openBlock(), _createBlock(_component_GraphiqueACourbe, {
                              key: 1,
                              graphInfo: graphiqueDataItem.graphInfo,
                              panelDouble: false,
                              graphAxisName: graphiqueDataItem.graphAxisName,
                              data: graphiqueDataItem.data,
                              fieldValue: graphiqueDataItem.fields,
                              translator: _ctx.translator
                            }, null, 8, ["graphInfo", "graphAxisName", "data", "fieldValue", "translator"]))
                          : _createCommentVNode("", true),
                        (graphiqueDataItem.graphInfo.graphType === 'tarte')
                          ? (_openBlock(), _createBlock(_component_GraphiqueATarte, {
                              key: 2,
                              graphInfo: graphiqueDataItem.graphInfo,
                              panelDouble: false,
                              graphAxisName: graphiqueDataItem.graphAxisName,
                              data: graphiqueDataItem.data,
                              fieldValue: graphiqueDataItem.fields,
                              translator: _ctx.translator
                            }, null, 8, ["graphInfo", "graphAxisName", "data", "fieldValue", "translator"]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_GraphSources, {
                          graphInfoProp: graphiqueDataItem.graphInfo,
                          data: graphiqueDataItem.data
                        }, null, 8, ["graphInfoProp", "data"])
                      ]),
                      _: 2
                    }, 1032, ["data", "graphInfo", "idCanvas", "id"]),
                    _createVNode(_component_EndroitError, {
                      erreurs: graphiqueDataItem.data.errors
                    }, null, 8, ["erreurs"])
                  ]),
                  _: 2
                }, 1024))
              : _createCommentVNode("", true),
            (!graphiqueDataItem.graphInfo.aucunTableau)
              ? (_openBlock(), _createBlock(_component_TabPanel, {
                  key: 1,
                  header: "Tableau"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Exports, {
                      data: graphiqueDataItem.data,
                      graphInfo: graphiqueDataItem.graphInfo,
                      idCanvas: 'tableau-' + graphiqueDataItem.graphInfo.id,
                      id: 'tableau-' + graphiqueDataItem.graphInfo.id,
                      class: "colonne-soit-graphique-soit-tableau"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Tableau, {
                          panelDouble: false,
                          data: graphiqueDataItem.data,
                          graphInfo: graphiqueDataItem.graphInfo
                        }, null, 8, ["data", "graphInfo"]),
                        _createVNode(_component_GraphSources, {
                          graphInfoProp: graphiqueDataItem.graphInfo,
                          data: graphiqueDataItem.data
                        }, null, 8, ["graphInfoProp", "data"])
                      ]),
                      _: 2
                    }, 1032, ["data", "graphInfo", "idCanvas", "id"]),
                    _createVNode(_component_EndroitError, {
                      erreurs: graphiqueDataItem.data.errors
                    }, null, 8, ["erreurs"])
                  ]),
                  _: 2
                }, 1024))
              : _createCommentVNode("", true),
            (!(graphiqueDataItem.graphInfo.aucunGraph || graphiqueDataItem.graphInfo.aucunTableau))
              ? (_openBlock(), _createBlock(_component_TabPanel, {
                  key: 2,
                  header: "Graphique et tableau"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_Exports, {
                        data: graphiqueDataItem.data,
                        graphInfo: graphiqueDataItem.graphInfo,
                        idCanvas: 'graphique-' + graphiqueDataItem.graphInfo.id,
                        class: "colonnes-dans-graphique-et-tableau"
                      }, {
                        default: _withCtx(() => [
                          (graphiqueDataItem.graphInfo.graphType === 'bande')
                            ? (_openBlock(), _createBlock(_component_GraphiqueABande, {
                                key: 0,
                                graphDataConverter: _ctx.graphDataConverter,
                                graphInfo: graphiqueDataItem.graphInfo,
                                panelDouble: true,
                                graphAxisName: graphiqueDataItem.graphAxisName,
                                data: graphiqueDataItem.data,
                                fieldValue: graphiqueDataItem.fields
                              }, null, 8, ["graphDataConverter", "graphInfo", "graphAxisName", "data", "fieldValue"]))
                            : _createCommentVNode("", true),
                          (graphiqueDataItem.graphInfo.graphType === 'courbe')
                            ? (_openBlock(), _createBlock(_component_GraphiqueACourbe, {
                                key: 1,
                                graphInfo: graphiqueDataItem.graphInfo,
                                panelDouble: true,
                                graphAxisName: graphiqueDataItem.graphAxisName,
                                data: graphiqueDataItem.data,
                                fieldValue: graphiqueDataItem.fields
                              }, null, 8, ["graphInfo", "graphAxisName", "data", "fieldValue"]))
                            : _createCommentVNode("", true),
                          (graphiqueDataItem.graphInfo.graphType === 'tarte')
                            ? (_openBlock(), _createBlock(_component_GraphiqueATarte, {
                                key: 2,
                                graphInfo: graphiqueDataItem.graphInfo,
                                panelDouble: false,
                                graphAxisName: graphiqueDataItem.graphAxisName,
                                data: graphiqueDataItem.data,
                                fieldValue: graphiqueDataItem.fields,
                                translator: _ctx.translator
                              }, null, 8, ["graphInfo", "graphAxisName", "data", "fieldValue", "translator"]))
                            : _createCommentVNode("", true),
                          _createVNode(_component_GraphSources, {
                            graphInfoProp: graphiqueDataItem.graphInfo,
                            data: graphiqueDataItem.data
                          }, null, 8, ["graphInfoProp", "data"])
                        ]),
                        _: 2
                      }, 1032, ["data", "graphInfo", "idCanvas"]),
                      _createVNode(_component_Exports, {
                        data: graphiqueDataItem.data,
                        graphInfo: graphiqueDataItem.graphInfo,
                        idCanvas: 'tableau-' + graphiqueDataItem.graphInfo.id,
                        class: "colonnes-dans-graphique-et-tableau",
                        onStartExport: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeIsExporting(true))),
                        onEndExport: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeIsExporting(false)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Tableau, {
                            panelDouble: true,
                            data: graphiqueDataItem.data,
                            graphInfo: graphiqueDataItem.graphInfo
                          }, null, 8, ["data", "graphInfo"]),
                          (_ctx.isExporting)
                            ? (_openBlock(), _createBlock(_component_GraphSources, {
                                key: 0,
                                graphInfoProp: graphiqueDataItem.graphInfo,
                                data: graphiqueDataItem.data
                              }, null, 8, ["graphInfoProp", "data"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["data", "graphInfo", "idCanvas"])
                    ]),
                    _createVNode(_component_EndroitError, {
                      erreurs: graphiqueDataItem.data.errors
                    }, null, 8, ["erreurs"])
                  ]),
                  _: 2
                }, 1024))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1024)
      ], 8, _hoisted_2))
    }), 128))
  ], 8, _hoisted_1))
}