
import { defineComponent } from 'vue'
import FileUpload from 'primevue/fileupload';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import TypeDeDonneeRecencement from '../configs/dropdown/Recensements/TypeDeDonneeRecencement.json';
import TypeDeFichierEndroits from '../configs/dropdown/Endroits/TypeDeFichierEndroits.json';
import RecensementsFileTypesPossibles from '../configs/dropdown/Recensements/SubCategoryRecensements/FileTypesPossibles.json';
import FilesTypesPossibleISQ from '../configs/dropdown/Recensements/SubCategoryRecensements/FilesTypesPossibleISQ.json';
import RoleFichierRecensement from '../configs/dropdown/Recensements/RoleFichierRecensement.json';
import ProgressSpinner from 'primevue/progressspinner';

export default defineComponent({
  name: 'Uploader',
  components: {
    FileUpload,
    Button,
    Dropdown,
    DataTable,
    Column,
    InputText,
    ConfirmDialog,
    InputNumber,
    ProgressSpinner
  },
  computed: {
    typeDeFichier () {
      if (this.categorieDonnee === this.categorieDonneeOptions[0]) {
        return TypeDeFichierEndroits;
      } else {
        return TypeDeDonneeRecencement;
      }
    },
    roleDeFichierRecencement () {
      return RoleFichierRecensement;
    },
    sousTypeDeFichier () {
      if (this.typeDonneesSelectionnee.id === 'donneesISQ') {
        return FilesTypesPossibleISQ;
      } else {
        return RecensementsFileTypesPossibles;
      }
    }
  },
  data () {
    return {
      compteurIntervalThread: undefined,
      categorieDonnee: '',
      categorieDonneeOptions: [
        'Endroits',
        'Recensements'
      ],
      subTypeDonneesSelectionnee: {} as any,
      typeDonneesSelectionnee: {} as any,
      nbFichierAUploader: 0,
      timer: 0,
      timerAffiche: '',
      showSpinner: false,
      fileList: new Array<any>()
    }
  },
  methods: {
    onTypeFichierChange () {
      this.nbFichierAUploader = this.typeDonneesSelectionnee.fileUploadLimit
    },
    onUploadFile (event: any) {
      this.fileList = event.files.map((item: File) => {
        return {
          file: item,
          nom: item.name,
          role: '',
          ligneHeaders: 1
          // ligneDebutMetas: 0,
          // ligneNbMetaLignes: 0
        }
      })
    },
    resetRecensements () {
      this.$confirm.require({
        message: 'Voulez-vous supprimer les infos des recensements de la base de données?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$store.dispatch('recensement/resetRecensements')
        }
      });
    },
    resetEndroits () {
      this.$confirm.require({
        message: 'Voulez-vous supprimer les infos des endroits de la base de données?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$store.dispatch('endroit/deleteEndroits')
        }
      });
    },
    augmenteTimer () {
      this.timer += 1;
      const hr = Math.floor(this.timer / 3600);
      const min = Math.floor((this.timer - hr * 3600) / 60);
      const sec = this.timer % 60;
      this.timerAffiche = hr + ':' + String('0' + min).slice(-2) + ':' + String('0' + sec).slice(-2);
    },
    submit () {
      const isEndroit = this.categorieDonnee === this.categorieDonneeOptions[0];
      const body = new FormData()
      body.append('fileCount', this.fileList.length.toString())
      body.append('typeDonnees', this.typeDonneesSelectionnee.id)
      for (let x = 0; x < this.fileList.length; x++) {
        body.append('file_' + x, this.fileList[x].file)
        if (!isEndroit) {
          body.append('file_' + x + '_headerLine', this.fileList[x].ligneHeaders)
          // body.append('file_' + x + '_metaStart', this.fileList[x].ligneDebutMetas)
          body.append('file_' + x + '_annee', this.fileList[x].annee)
          // body.append('file_' + x + '_metaNb', this.fileList[x].ligneNbMetaLignes)
          if (this.typeDonneesSelectionnee.id === 'total') {
            body.append('file_' + x + '_role', this.fileList[x].role.id)
          }
        }
        if (this.typeDonneesSelectionnee !== {} && (this.typeDonneesSelectionnee.id === 'donneesQuebec' || this.typeDonneesSelectionnee.id === 'donneesISQ') && this.subTypeDonneesSelectionnee !== {}) {
          body.append('fileType', this.subTypeDonneesSelectionnee.id)
        }
        // body.append('file_' + x + '_role', this.fileList[x].role)
      }
      let storeRoute = 'recensement/uploadRecensements';
      if (isEndroit) {
        storeRoute = 'endroit/uploadEndroits';
      }
      clearInterval(this.compteurIntervalThread);
      this.timerAffiche = '';
      this.$store.dispatch(storeRoute, body).then(() => {
        clearInterval(this.compteurIntervalThread);
        this.timerAffiche = '';
        this.showSpinner = false;
        this.timer = 0;
      }).catch(() => {
        clearInterval(this.compteurIntervalThread);
        this.showSpinner = false;
        this.timerAffiche = '';
        this.timer = 0;
      });
      this.showSpinner = true;
      this.timer = 0;
      this.compteurIntervalThread = setInterval(this.augmenteTimer, 1000) as any;
    }
  },
  unmounted () {
    clearInterval();
  }
})
