<template>
  <router-view :key="$route.path"/>
</template>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Encode+Sans:wght@400;500;600;700&display=swap');

html {
  height: 100%;
}

.boutonbleu {
  background-color: #F0753B;
  border-radius: 5px;
  box-shadow: none;
  border: none;
  color: white;
  font-weight: 700;
  opacity: 1;

  &:hover {
    background: #D05419;
    cursor: pointer;
  }
}

#app {
  font-family: 'Encode Sans', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

body {
  background: #ebebeb;
  height: 100%;
  margin: 0;
  padding: 0;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

// Pour changer la taille des icônes X et +, aux trois endroits où ils sont utilisés, Le X n'étant qu'un + ayant subi une rotation. La librairie Prime Icons ne comportait pas de X.
.pi.pi-plus {
  font-size: 0.8rem;
  margin-left: 0.2em;
}

.selectCursorOnHover {
  cursor: pointer
}

.graphique-source-font-size {
  font-size: 11px
}

// couleurs des titres "Municipalités recherchées", "Rechercher par nom" et "Rechercher des municipalités sur la carte"
.titreFiltre {
  color: #001B33;
}
</style>
