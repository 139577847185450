
import { defineComponent, PropType, inject, Ref } from 'vue'
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import couleurs from '@/configs/couleurs.json';
import am4themesKelly from '@amcharts/amcharts4/themes/kelly';
import { FormatedGraphiqueDataItemDTO } from '@/DTO/FormatedGraphiqueDataItemDTO';
import { DescriptionGraphiqueDTO } from '@/DTO/DescriptionGraphiqueDTO';
import { RecensementDataItem } from '@/DTO/RecensementDataItem';
import { GraphErrorDTO } from '@/DTO/GraphErrorDTO';
import { EndroitSelectionHelper } from '@/helpers/EndroitSelectionHelper';

function am4themesMyTheme (target: any) {
  // On crée le spectre de couleurs pour le chart avec les couleurs dans le fichier de config
  if (target instanceof am4core.ColorSet) {
    target.list = couleurs.map(couleur => am4core.color(couleur));
  }
}

export default defineComponent({
  name: 'GraphiqueATarte',
  props: {
    data: {
      type: Object as PropType<FormatedGraphiqueDataItemDTO>,
      required: true
    },
    graphInfo: {
      type: Object as PropType<DescriptionGraphiqueDTO>,
      required: true
    },
    panelDouble: Boolean,
    fieldValue: Array,
    graphAxisName: Object
  },
  data () {
    return {
      chart: {} as am4charts.PieChart,
      chartData: {} as Map<string, Array<RecensementDataItem>>,
      errors: {} as Map<string, GraphErrorDTO>
    }
  },
  methods: {
    createGraph () {
      am4core.options.autoSetClassName = true;
      (this.$refs.chartdiv as HTMLElement).innerHTML = '';
      const targetDiv = document.createElement('div') as HTMLDivElement;
      let heightMultiplier = 1;
      if (this.graphInfo.heightMultiplier !== undefined) {
        heightMultiplier = this.graphInfo.heightMultiplier;
      }
      targetDiv.style.minHeight = (600 * heightMultiplier) + 'px';
      // targetDiv.style.width = '100%';
      (this.$refs.chartdiv as HTMLElement).appendChild(targetDiv)
      const chart = am4core.create(targetDiv, am4charts.PieChart);
      // Pour empêcher que le dernier libellé de l'axe des X soit tronqué en fin de ligne et devienne partialement invisible. Voir billets 631 (condanmné en raison d'un bogue étrange de Git) et 646 à ce sujet au besoin. Si on désire changer cela dans GraphiqueABande.vue, il serait logique de changer aussi GraphiqueACourbe.vue.
      // chart.paddingRight = 30;
      chart.data = Array.from(this.chartData.values()).flat();
      // création et configuration de la légende
      chart.legend = new am4charts.Legend();
      chart.legend.valueLabels.template.text = '';
      // chart.maskBullets = false;

      // chart.zoomOutButton.disabled = true;

      chart.language.locale._thousandSeparator = ' ';

      const title = chart.titles.create();

      // c'est ici que ça se passe pour le style des titres
      // si on modifie les titres dans GraphiqueABande.vue, il faut le faire aussi dans GraphiqueACourbe.vue pour que l'appli soit uniforme
      // le secret est d'utiliser la propriété .maxWidth, qui doit être une taille fournie en pixels
      title.marginTop = 14;
      title.text = '[bold #495057]' + (this.graphInfo.name as string) + '[/]';
      title.text = title.text.toLocaleUpperCase();
      title.fontSize = 25;
      title.marginBottom = 30;
      title.wrap = true;
      title.truncate = false;
      title.maxHeight = 500;
      title.textAlign = 'middle';
      // Pour que le title.wrap s'applique, quand la fenêtre change ça re-dessine le title
      chart.events.on('sizechanged', function () {
        title.deepInvalidate()
      });
      this.setSpacingParameters(chart, title);
      this.chart = chart;
    },
    /*
      Setup les paramètre d'un graphiques pour l'espacement de celui-ci
    */
    setSpacingParameters (chart: am4charts.PieChart, title: am4core.Label) {
      chart.height = am4core.percent(100);
      title.marginBottom = 5;
    },
    generateGraphs () {
      this.createGraph();
      this.createSeries();
    },
    createSeries () {
      const chart = this.chart;
      const pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = 'total';
      pieSeries.dataFields.category = this.graphInfo.themeField ? this.graphInfo.themeField : 'reponse';
      pieSeries.labels.template.text = pieSeries.labels.template.text + ' ({value.value} rép.)';
      pieSeries.labels.template.wrap = true;
      pieSeries.labels.template.maxWidth = 150;
      // console.log(pieSeries)
      // const showPourcentage = this.graphInfo.pourcentageGraph;
      // const showDollarSign = this.graphInfo.showDollarSign;
      // for (const dataItem of this.chartData.values()) {
      //   if (dataItem.length > 0 && 'endroit' in dataItem[0]) {
      //     let nomChampSerie = 'nomEndroit';
      //     if (this.graphInfo.seriesBy) {
      //       nomChampSerie = this.graphInfo.seriesBy;
      //     }
      //     const series = chart.series.push(new am4charts.PieSeries());
      //     const name = dataItem[0][nomChampSerie];
      //     series.name = name;
      //     series.data = dataItem;
      //     let categoryName = 'anneeRecensement'
      //     if (this.graphInfo.themeField !== undefined) {
      //       categoryName = this.graphInfo.themeField;
      //     }
      //     series.dataFields.category = categoryName;

      //     // Création des valeurs pour l'axe des Y
      //     if (this.graphInfo.pourcentageAxis) {
      //       series.dataFields.value = 'pourcentage';
      //     } else {
      //       series.dataFields.value = 'total';
      //     }

      //     if (this.graphInfo.showLibellesLegendeSousGraphique === false) {
      //       series.hiddenInLegend = true;
      //     }
      //     series.strokeWidth = 3;
      //     const circleBullet = series.bullets.push(new am4charts.CircleBullet());

      //     if (this.graphInfo.pourcentageGraph) {
      //       if (this.graphInfo.contenuUnitesDansLaBande) {
      //         // si le graphique comporte à la fois un pourcentage et une unité particulière, comme " hab." pour le graphique 11, voici le format attendu: "50 % (538 015 hab.)".
      //         circleBullet.tooltipText = `{categoryX}: [bold]{pourcentageLabel} ({bulletText} ${this.graphInfo.contenuUnitesDansLaBande})[/]`;
      //       } else {
      //         // si le contenu de la chaîne d'unités est vide, mais "truthy" (= est défini) et qu'il y a bien présence d'un pourcentage, c'est qu'il faut afficher le nombre (sans unités) et le pourcentage dans l'infobulle
      //         circleBullet.tooltipText = '{categoryX}: [bold]{pourcentageLabel} ({bulletText})[/]';
      //       }
      //     } else {
      //       if (this.graphInfo.contenuUnitesDansLaBande) {
      //         circleBullet.tooltipText = `{categoryX}: [bold]{bulletText} ${this.graphInfo.contenuUnitesDansLaBande}[/]`;
      //       } else {
      //         circleBullet.tooltipText = '{categoryX}: [bold]{bulletText}[/]';
      //       }
      //     }

      //     circleBullet.circle.radius = 10;
      //     const valueLabel = series.bullets.push(new am4charts.LabelBullet());
      //     // valueLabel.label.text = '{valueY}';

      //     const showSecondary = this.graphInfo.secondaryInGraph === undefined || this.graphInfo.secondaryInGraph;
      //     if (showPourcentage) {
      //       // if (this.graphInfo.contenuUnitesDansLaBande) {
      //       //   valueLabel.label.text = `{totalFormatte} ${this.graphInfo.contenuUnitesDansLaBande}`;
      //       // }
      //       valueLabel.label.text = '{pourcentageLabel}'
      //       if (showSecondary) {
      //         if (showDollarSign) {
      //           valueLabel.label.text += ' ({bulletText}$)';
      //         } else {
      //           valueLabel.label.text += ' ({bulletText})';
      //         }
      //       }
      //     } else if (showDollarSign) {
      //       valueLabel.label.text = '{bulletText}';
      //     } else {
      //       valueLabel.label.text = '{totalFormatte}';
      //     }

      //     valueLabel.label.horizontalCenter = 'middle';
      //     valueLabel.label.dy = -20;
      //     valueLabel.label.hideOversized = false;
      //     valueLabel.label.truncate = false;
      //     if (this.graphInfo.boldLabels) {
      //       valueLabel.label.fontWeight = 'bold';
      //     }
      //   }
      // }
    },
    handleDesactivateEndroit () {
      const endroitSelectionHelper = inject('endroitSelectionHelper') as Ref<EndroitSelectionHelper>;
      const endroitsDesactives = endroitSelectionHelper.value.getAllDesactivatedEndroits();
      // console.log(endroitsDesactives)
      this.data.data.forEach((question:any) => {
        question.forEach((questionReponse:any) => {
          if (questionReponse.endroitsIds) {
            questionReponse.endroitsIds.forEach((endroitReponse:any) => {
              if (endroitsDesactives.includes(endroitReponse._id)) {
                questionReponse.total = questionReponse.total - 1;
              }
            });
          }
        });
      });
    }
  },
  mounted () {
    this.chartData = this.data.data;
    this.errors = this.data.errors;
    this.handleDesactivateEndroit();
    this.generateGraphs();
  },
  created () {
    am4core.useTheme(am4themesMyTheme);
    // am4core.useTheme(am4themesKelly);
  }
})
