
import { defineComponent } from 'vue'
import MultiSelect from 'primevue/multiselect';
import Tag from 'primevue/tag';

import Dropdown from 'primevue/dropdown';
import { QuestionnaireDropdownChangeResponse } from '@/DTO/QuestionnaireDropdownChangeResponse';
import { FilterKeyValue } from '@/DTO/FilterKeyValue';

export default defineComponent({
  name: 'InputTagMaison',
  components: {
    Tag,
    Dropdown
  },
  data () {
    return {
      allOptionData: [] as Array<any>,
      optionData: [] as Array<any>,
      filteredOption: [] as Array<any>,
      errorMessage: '',
      value: '',
      selectedRegion: null,
      regionOptions: this.regions,
      selectedMrc: null,
      mrcOptions: this.mrc,
      selectedMunicipalite: null,
      municipaliteOptions: this.municipalites,
      listMunicipalite: [] as Array<any>

    }
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    questionProp: {
      type: Object,
      required: true
    },
    questionId: {
      type: Number,
      required: false
    },
    filterWith: {
      type: Object,
      required: false
    },
    regions: {
      type: Object,
      required: false
    },
    mrc: {
      type: Object,
      required: false
    },
    municipalites: {
      type: Object,
      required: false
    }
  },
  watch: {
    questionProp (value) {
      this.optionData = value.reponsesPossibles;
      this.optionData.sort((a: any, b: any) => {
        if (a.texteReponse > b.texteReponse) {
          return 1;
        } else if (a.texteReponse < b.texteReponse) {
          return -1;
        } else {
          return 0;
        }
      });
      this.allOptionData = value.reponsesPossibles;
      this.allOptionData.sort((a: any, b: any) => {
        if (a.texteReponse > b.texteReponse) {
          return 1;
        } else if (a.texteReponse < b.texteReponse) {
          return -1;
        } else {
          return 0;
        }
      });
    },
    filterWith (value: FilterKeyValue) {
      if (value.parentId) {
        if (this.allOptionData.length > 0) {
          if (this.allOptionData[0].idParent != null) {
            this.optionData = this.allOptionData.filter(x => x.idParent === value.parentId)
          }
        }
      }
    }
  },
  emits: ['update:modelValue', 'change'],
  mounted () {
    this.optionData = this.questionProp.reponsesPossibles;
    this.optionData.sort((a: any, b: any) => {
      if (a.texteReponse > b.texteReponse) {
        return 1;
      } else if (a.texteReponse < b.texteReponse) {
        return -1;
      } else {
        return 0;
      }
    });
    this.allOptionData = this.questionProp.reponsesPossibles;
    this.allOptionData.sort((a: any, b: any) => {
      if (a.texteReponse > b.texteReponse) {
        return 1;
      } else if (a.texteReponse < b.texteReponse) {
        return -1;
      } else {
        return 0;
      }
    });

    this.filteredOption = this.optionData.filter(data => data.texteReponse.toLowerCase());
  },
  methods: {
    removeMunicipalite (municipalite: any) {
      this.listMunicipalite = this.listMunicipalite.filter((item) => {
        return item.id !== municipalite.id;
      })
    },
    addMunicpalite () {
      if (this.selectedMunicipalite !== null) {
        if (!this.listMunicipalite.includes(this.selectedMunicipalite)) {
          this.listMunicipalite.push(this.selectedMunicipalite);
        }
      } else if (this.selectedMrc !== null) {
        if (this.municipaliteOptions !== undefined) {
          this.municipaliteOptions.forEach((element:any) => {
            if (!this.listMunicipalite.includes(element)) {
              this.listMunicipalite.push(element);
            }
          });
        }
      }
      this.$emit('update:modelValue', this.listMunicipalite);
    },
    searchMunicipalite (event: any) {
      this.filteredOption = this.optionData.filter(data => data.texteReponse.toLowerCase().includes(event.query.toLowerCase()));
    },
    onValueChange (type:any) {
      if (type === 'region') {
        if (this.regionOptions !== undefined && this.mrcOptions !== undefined) {
          if (this.selectedRegion !== null && this.mrc !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore: Object is possibly 'null'.
            this.mrcOptions = this.mrc.filter((item:any) => item.idParent === this.selectedRegion.id);

            // remet vide les 2 prochains dropdowns
            this.selectedMrc = null;
            this.selectedMunicipalite = null;
          }
        }
      }
      if (type === 'mrc') {
        if (this.mrcOptions !== undefined && this.municipaliteOptions !== undefined) {
          if (this.selectedMrc !== null) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore: Object is possibly 'null'.
            this.municipaliteOptions = this.municipalites.filter((item:any) => item.idParent === this.selectedMrc.id);
          }
        }
      }
    },
    validate () {
      // Si la longueur de l'array this.value est de zéro, c'est qu'aucune réponse n'a été fournie.
      if (this.value.length === 0) {
        this.errorMessage = 'Réponse manquante.';
      } else {
        // On s'assure de vider la variable de message d'erreur s'il n'y a pas/plus d'erreur. Autrement, après une première erreur de la part de l'utilisateur, le message d'erreur ne partira jamais.
        this.errorMessage = '';
      }
    }
  }
})
