
import { defineComponent, PropType, inject, Ref } from 'vue'
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import couleurs from '@/configs/couleurs.json';
import am4themesKelly from '@amcharts/amcharts4/themes/kelly';
import { FormatedGraphiqueDataItemDTO } from '@/DTO/FormatedGraphiqueDataItemDTO';
import { DescriptionGraphiqueDTO } from '@/DTO/DescriptionGraphiqueDTO';
import { GraphDataConverter } from '@/helpers/GraphDataConverter';
import { EndroitSelectionHelper } from '@/helpers/EndroitSelectionHelper';
import SpecialStringHandler from '@/helpers/SpecialStringHandler';
import { AxisItemLocation } from '@amcharts/amcharts4/.internal/charts/axes/Axis';

function am4themesMyTheme (target: any) {
  // On crée le spectre de couleurs pour le chart avec les couleurs dans le fichier de config
  if (target instanceof am4core.ColorSet) {
    target.list = couleurs.map(couleur => am4core.color(couleur));
  }
}

export default defineComponent({
  name: 'GraphiqueABande',
  props: {
    data: {
      type: Object as PropType<FormatedGraphiqueDataItemDTO>,
      required: true
    },
    graphInfo: {
      type: Object as PropType<DescriptionGraphiqueDTO>,
      required: true
    },
    graphDataConverter: {
      type: Object as PropType<GraphDataConverter>,
      required: true
    },
    panelDouble: Boolean,
    fieldValue: Array,
    graphAxisName: Object
  },
  setup () {
    const endroitSelectionHelper = inject('endroitSelectionHelper') as Ref<EndroitSelectionHelper>
    const selectedEndroit = endroitSelectionHelper.value.getAllSelectedEndroits();
    return {
      selectedEndroit
    }
  },
  data () {
    return {
      valueAxis: {} as am4charts.ValueAxis,
      chart: {} as am4charts.XYChart,
      chartData: {} as any
    }
  },
  computed: {
    graphDataContainOneItemPerList () {
      return !(Array.of(...this.chartData.values()).some((item: any) => item.length !== 1));
    }
  },
  methods: {
    createGraph () {
      (this.$refs.chartdiv as HTMLElement).innerHTML = '';
      const targetDiv = document.createElement('div') as HTMLDivElement;
      let heightMultiplier = 1;
      if (this.graphInfo.heightMultiplier !== undefined) {
        heightMultiplier = this.graphInfo.heightMultiplier;
      }
      if (this.graphInfo.graphHauteurAdaptative === true) {
        var customHeight = 100 * heightMultiplier * this.selectedEndroit.length;
        if (this.graphInfo.graphHauteurAdaptativeByTheme === true) {
          const listTheme = new Array<string>();
          var colonneTheme = 'theme';
          if (this.graphInfo.themeField !== undefined) {
            colonneTheme = this.graphInfo.themeField;
          }
          this.chartData.forEach((item: any) => {
            if (item.length > 1) {
              item.forEach((y:any) => {
                if (!listTheme.includes(y[colonneTheme])) {
                  listTheme.push(y[colonneTheme]);
                }
              })
            }
          });

          customHeight = 50 * heightMultiplier * listTheme.length;
        }
        if (customHeight < 600) {
          customHeight = 600;
        }
        targetDiv.style.minHeight = (customHeight) + 'px';
      } else {
        targetDiv.style.minHeight = (600 * heightMultiplier) + 'px';
      }
      (this.$refs.chartdiv as HTMLElement).appendChild(targetDiv)
      const chart = am4core.create(targetDiv, am4charts.XYChart);
      // Pour empêcher que le dernier libellé de l'axe des X soit tronqué en fin de ligne et devienne partiellement invisible. Voir billets 631 (condanmné en raison d'un bogue étrange de Git) et 646 à ce sujet au besoin. Si on désire changer cela dans GraphiqueABande.vue, il serait logique de changer aussi GraphiqueACourbe.vue.
      chart.paddingRight = 40;
      chart.maskBullets = false;

      // Sorting des données

      let evaluatedParamName = 'total';
      if (this.graphInfo.pourcentageAxis) {
        evaluatedParamName = 'pourcentage'
      }
      // console.log(this.graphInfo.id)w
      const seriesNameTo = this.graphInfo.seriesNameTo;
      if (this.graphInfo.seriesNameTo === 'Non-immigrants') {
        // debugger;
      }

      let chartDataEntriesSorted;
      if (this.graphInfo.sortByBackEnd === undefined || this.graphInfo.sortByBackEnd !== true) {
        chartDataEntriesSorted = Array.from(this.chartData);
      } else {
        chartDataEntriesSorted = Array.from(this.chartData.entries()).sort((a: any, b: any) => {
          const aKey = a[0];
          const aValue = a[1];
          const [bKey, bValue] = b;
          if (seriesNameTo === aKey) {
            return -1;
          }
          if (seriesNameTo === bKey) {
            return 1;
          }
          const maxA = Math.max(...aValue.map((item: any) => item[evaluatedParamName]))
          const maxB = Math.max(...bValue.map((item: any) => item[evaluatedParamName]))
          // console.log(maxA, maxB)
          return maxA - maxB
        });
      }
      const mapChartDataSort = chartDataEntriesSorted.map((item: any) => item[1]);
      let chartDataSort = new Array<any>();
      mapChartDataSort.forEach((itemList: any) => {
        itemList.forEach((item: any) => {
          chartDataSort.push(item);
        })
      });
      if (chartDataSort !== undefined && this.graphInfo.order === true) {
        const listTheme = new Map();
        let ligne: any;
        for (const ligneIndex in chartDataSort) {
          ligne = chartDataSort[ligneIndex];
          let arrayOfLine = [];
          if (listTheme.has(ligne.theme)) {
            arrayOfLine = listTheme.get(ligne.theme);
          }
          arrayOfLine.push(ligne);
          listTheme.set(ligne.theme, arrayOfLine);
        }
        chartDataSort = chartDataSort.sort((a: any, b: any) => {
          if (a.theme === seriesNameTo && b.theme !== seriesNameTo) {
            return 1;
          }
          if (b.theme === seriesNameTo && a.theme !== seriesNameTo) {
            return -1;
          }
          const totalA = a[evaluatedParamName];
          const totalB = b[evaluatedParamName];
          return totalA - totalB;
        })
      }
      if (this.graphInfo.reverseSeries === true) {
        chartDataSort.reverse();
      }
      // chartDataSort.reverse();
      chart.data = chartDataSort;
      // console.log(chart.data)

      // if (chart.data.length === 1) {
      //   chart.data[0] = chart.data[0].sort((a: any, b: any) => {
      //     const maxA = a[evaluatedParamName]
      //     const maxB = b[evaluatedParamName]
      //     return maxA - maxB
      //   })
      // }

      // chart.data = Array.from(this.chartData.values()).flat()
      // Logique pour cacher/afficher la légende sous le graphique (petits carrés colorés accompagnés d'un libellé). Cette condition ne sert qu'à une chose: cacher le mot "Municipalité" dans certains graphiques à bandes comme "DENSITÉ DE POPULATION AU KM² (2016)".
      chart.legend = new am4charts.Legend();
      chart.zoomOutButton.disabled = true;

      am4core.options.autoSetClassName = true;

      // séparateur des milliers dans l'axe des X et des Y
      chart.language.locale._thousandSeparator = ' ';
      const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.renderer.cellStartLocation = 0.1;
      categoryAxis.renderer.cellEndLocation = 0.9;
      // categoryAxis.renderer.grid.values.forEach(element => {
      //   element.axis.contentHeight = 1000;
      // });
      // console.log(categoryAxis.renderer.grid.values);
      if (this.graphInfo.graphBandeInverser === true) {
        categoryAxis.renderer.inversed = true;
      }
      if (this.graphDataContainOneItemPerList) {
        categoryAxis.dataFields.category = 'nomEndroit';
      } else if (this.graphInfo.themeField !== undefined) {
        categoryAxis.dataFields.category = this.graphInfo.themeField;
      } else if (this.graphInfo.displayFields.includes('theme')) {
        categoryAxis.dataFields.category = 'theme';
      } else {
        categoryAxis.dataFields.category = 'anneeRecensement';
      }

      // Pour cacher les titres des tableaux sur l'axe des Y, à la demande de la cliente. Passage commenté plutôt qu'effacé au cas où la cliente changerait d'avis.
      // categoryAxis.title.text = (this.graphAxisName?.yAxis as string);
      // categoryAxis.title.fontSize = 16;

      categoryAxis.renderer.minGridDistance = 10;

      const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
      this.valueAxis = valueAxis;

      // Pour cacher les titres des tableaux sur l'axe des X, à la demande de la cliente. Passage commenté plutôt qu'effacé au cas où la cliente changerait d'avis.
      // valueAxis.title.text = (this.graphAxisName?.xAxis as string);
      // valueAxis.title.fontSize = 16;

      // Pour trouver le pourcentage le plus élevé dans une série de bandes avec pourcentage. Après la détermination de ce pourcentage il devient possible d'ajuster la longueur de l'axe des X pour la limiter selon le contexte. Par exemple: pourcentage le plus élevé de 50 % dans un graphique, donc possibilité pour l'axe des X de se rendre jusqu'à 60 % pour donner plus d'espace aux bandes.
      // En lien avec le billet 648.
      let pourcentageLePlusEleve = 0;

      for (const item of chart.data) {
        pourcentageLePlusEleve = pourcentageLePlusEleve < item.pourcentage ? item.pourcentage : pourcentageLePlusEleve;
      }

      valueAxis.renderer.labels.template.width = 90;
      valueAxis.renderer.labels.template.wrap = true;

      // Pour cacher ou non les libellés de l'axe des X. C'est par exemple ce qu'on fait dans le graphique 11, car les pourcentages sont déjà indiqués au bout de chaque bande.
      if (this.graphInfo.cacheLibellesAxeDesX) {
        valueAxis.renderer.labels.template.disabled = true;
      }

      valueAxis.renderer.labels.template.truncate = false;

      /**
       * Pour donner plus d'espace aux bandes horizontales, surtout quand le pourcentage qu'elles représentent est petit (inférieur à 10). Cela dit, ce n'est pas une science exacte. Il faut s'assurer entre autres que le pourcentage à la droite de la bande n'est pas si poussé si loin qu'il se retrouve caché. Ce phénomène est particulièrement susceptible de se produire pour les 85 ans et plus, pour cette requête:
       *
       * http://localhost:8080/home/11?endroits=618d424a0bfeb2d99f4c89ca,618d44210bfeb2d99f5ce914,618d44240bfeb2d99f5d036f,618d44280bfeb2d99f5d1dcc,618d442b0bfeb2d99f5d3828,618d442e0bfeb2d99f5d5284
       */
      if (this.graphInfo.pourcentageAxis) {
        valueAxis.min = 0;

        if (pourcentageLePlusEleve < 80) {
          valueAxis.extraMax = 0.1;
          // modification pour tanter de corriger le dépassement de l'axe sur pourcentage
          // valueAxis.max = pourcentageLePlusEleve + 15;
        } else {
          valueAxis.extraMax = 0.1;
          // valueAxis.max = pourcentageLePlusEleve + 20;

          /**
           * À la base, sans la ligne de code ci-dessous, dès qu'on arrive à une longueur d'axe de 101 et plus, pour une raison étrange, l'axe se rend jusqu'à 150 %, ce qui rend les bandes horizontales moins visibles. Le phénomène est particulièrement visible avec la requête ci-dessous. Pour mieux comprendre ce qui se passe, il peut être pertinent de changer temporairement à "false" la valeur de la clé cacheLibellesAxeDesX, dans graphiques.json.
           *
           * http://localhost:8080/home/10?endroits=618d42120bfeb2d99f4a7a96,618d505b0bfeb2d99fb5430d,618d50630bfeb2d99fb577c2,618d4b200bfeb2d99f92ddf7,618d51e30bfeb2d99fbea200&endroitsDesactives=4,3
           */

          valueAxis.renderer.minGridDistance = 30;
        }

      // autre chose que des pourcentages
      } else if (this.data.minData !== undefined && this.data.maxData !== undefined) {
        valueAxis.min = this.data.minData - this.data.minData * 0.1;
        valueAxis.max = this.data.maxData;
        // valueAxis.extraMax = 0.1;
      // probablement pour les pourcentages aussi, ce qui fait double emploi avec la toute première condition
      } else {
        valueAxis.min = 0;
        valueAxis.max = 100;
      }
      valueAxis.calculateTotals = true;
      valueAxis.numberFormatter = new am4core.NumberFormatter();

      /**
       * Format des nombres.
       *
       * Avant, ce qu'on avait comme format ci-dessous était '#a'. De cette manière, on n'avait pas la séparation des milliers. Il a donc fallu changer cela. La première solution envisagée a été '#,###|(#,###s)|"-"', mais cela faisait disparaître le zéro dans certains graphiques, en plus de décoller l'axe des Y du reste du tableau et par conséquent de rendre le tableau plus étroit, et donc de cacher le premier chiffre des valeurs du tableau. Bref, la solution actuelle ne comporte que des avantages.
       *
       * La raison pour laquelle la virgule ci-dessous est convertie en espace est la présence de cette ligne de code, plus haut:
       *
       * chart.language.locale._thousandSeparator = ' ';
       *
       * https://www.amcharts.com/docs/v4/concepts/formatters/formatting-numbers/#Negative_values_and_zeros
       */
      // valueAxis.numberFormatter.numberFormat = '#,###|(#,###s)';
      valueAxis.numberFormatter.numberFormat = '#,##a';

      /**
       * Suffixes pour les grands nombres.
       *
       * 300 000 000 ===> 300 M
       *
       * https://www.amcharts.com/docs/v4/tutorials/modifying-big-number-prefixes/
       */
      valueAxis.numberFormatter.bigNumberPrefixes = [
        { number: 1e+6, suffix: ' M' },
        { number: 1e+9, suffix: ' B' }
      ];

      const title = chart.titles.create();

      // c'est ici que ça se passe pour le style des titres
      // si on modifie les titres dans GraphiqueABande.vue, il faut le faire aussi dans GraphiqueACourbe.vue pour que l'appli soit uniforme
      // le secret est d'utiliser la propriété .maxWidth, qui doit être une taille fournie en pixels
      if (this.graphInfo.injectedName !== null) {
        const nomGeoTitle = chart.data[0].endroit.NOM_GEO.toUpperCase(); // je vais récupéré le nom geo de l'endroit du premier data
        title.text = '[bold #495057]' + SpecialStringHandler.replaceParamInString(this.graphInfo.name, nomGeoTitle) + '[/]';
      } else {
        title.text = '[bold #495057]' + (this.graphInfo.name as string) + '[/]';
      }
      title.fontSize = 25;
      title.marginBottom = 30;
      title.wrap = true;
      title.truncate = false;
      title.maxHeight = 500;
      title.textAlign = 'middle';
      // Pour que le title.wrap s'applique, quand la fenêtre change ça re-dessine le title
      chart.events.on('sizechanged', function () {
        title.deepInvalidate()
      });

      categoryAxis.renderer.labels.template.wrap = false;
      categoryAxis.renderer.labels.template.maxWidth = 200;
      // categoryAxis.renderer.cellStartLocation = 0.1;
      // categoryAxis.renderer.cellEndLocation = 1;
      this.chart = chart;
      this.setSpacingParameters(chart, title)
    },
    /*
      Setup les paramètres d'un graphique pour l'espacement de celui-ci
    */
    setSpacingParameters (chart: am4charts.XYChart, title: am4core.Label) {
      chart.height = am4core.percent(100);
      title.marginBottom = 5;
    },
    generateGraphs () {
      this.createGraph();
      this.createSeries();
      this.createMoyennes();
    },
    getMoyenneArray (): any[] {
      const moyenneArray = new Array<any>();

      for (const dataItem of this.chartData.values()) {
        dataItem.forEach((element: any) => {
          moyenneArray.push(element);
        });
      }

      return moyenneArray;
    },
    createSeries () {
      const chart = this.chart;
      const showPourcentage = this.graphInfo.pourcentageGraph as boolean;
      const showDollarSign = this.graphInfo.showDollarSign as boolean;
      const graphDataContainOneItemPerList = this.graphDataContainOneItemPerList;
      if (graphDataContainOneItemPerList && this.graphInfo.seriesBy !== 'theme') {
        const name = this.graphInfo?.YAxixName;
        this.createColumnSerie(chart, name, showPourcentage, showDollarSign);
      } else {
        let evaluatedParamName = 'total';
        if (this.graphInfo.pourcentageAxis) {
          evaluatedParamName = 'pourcentage'
        }
        const dataArray = Array.of(...this.chartData.values());
        // // Trouver le theme selon le plus gros total
        // const dataValues = dataArray.sort((a: any, b: any) => {
        //   let plusGrosseValeurA = 0;
        //   let plusGrosseValeurB = 0;

        //   Array.of(...a).forEach((item: any) => {
        //     if (item[evaluatedParamName] > plusGrosseValeurA) {
        //       plusGrosseValeurA = item[evaluatedParamName];
        //     }
        //   })
        //   Array.of(...b).forEach((item: any) => {
        //     if (item[evaluatedParamName] > plusGrosseValeurB) {
        //       plusGrosseValeurB = item[evaluatedParamName];
        //     }
        //   })
        //   return plusGrosseValeurA - plusGrosseValeurB
        // });
        let dataValues = [];
        if (this.graphInfo.forceOrder === true || ((this.graphInfo.sortByBackEnd === undefined || this.graphInfo.sortByBackEnd !== true) && this.graphInfo.seriesNameTo === undefined)) {
          dataValues = dataArray.sort((a: any, b: any) => {
            const maxA = Math.max(...a.map((item: any) => item[evaluatedParamName]))
            const maxB = Math.max(...b.map((item: any) => item[evaluatedParamName]))
            return maxA - maxB
          });

          if (dataValues.length === 1) {
            dataValues[0] = dataValues[0].sort((a: any, b: any) => {
              const maxA = a[evaluatedParamName]
              const maxB = b[evaluatedParamName]
              return maxA - maxB
            })
          }
        } else {
          dataValues = dataArray;
        }
        if (this.graphInfo.invertedSeries) {
          dataValues.reverse();
        }
        for (const dataItem of dataValues) {
          // Il arrive parfois que l'élément de la boucle soit vide. C'est par exemple le cas avec le graphique 32. Il faut donc s'assurer qu'il y ait des données à afficher avant de les afficher.
          if (dataItem.length > 0) {
            const name = (this.graphInfo.seriesBy !== undefined) ? dataItem[0][this.graphInfo.seriesBy] : dataItem[0].endroit.NOM_GEO;
            this.createColumnSerie(chart, name, showPourcentage, showDollarSign, dataItem);
          } // fin de la condition
        } // fin de la boucle
      } // fin du bloc else
    },
    createColumnSerie (chart: any, name: string, showPourcentage: boolean, showDollarSign: boolean, dataItem = undefined) {
      // const series = chart.series.push(new am4charts.ColumnSeries()) as am4charts.ColumnSeries;
      const series = chart.series.push(new am4charts.ColumnSeries());
      // Création des valeurs pour l'axe des Y
      if (dataItem !== undefined) {
        series.data = dataItem;
        if (this.graphDataContainOneItemPerList) {
          series.dataFields.categoryY = 'nomEndroit';
        } else if (this.graphInfo.themeField !== undefined) {
          series.dataFields.categoryY = this.graphInfo.themeField;
        } else if (this.graphInfo.displayFields.includes('theme')) {
          series.dataFields.categoryY = 'theme';
        } else {
          series.dataFields.categoryY = 'anneeRecensement';
        }
      } else {
        series.dataFields.categoryY = 'nomEndroit';
      }

      // Libellé accompagnant le petit carré coloré, dans la légende, sous le graphique. Le petit carré coloré est cliquable et sert à cacher/afficher les bandes du graphique.
      series.name = name;

      // Cache les légendes sous le graphiques si demandé
      if (this.graphInfo.showLibellesLegendeSousGraphique === false) {
        series.hiddenInLegend = true;
      }

      // Création des valeurs pour l'axe des X
      if (this.graphInfo.pourcentageAxis) {
        series.dataFields.valueX = 'pourcentage';
      } else {
        series.dataFields.valueX = 'total';
      }

      /**
       * création de l'infobulle (= celle qu'on voit au survol d'une bande du graphique et non celle qu'on voit au survol de la puce d'une barre verticale de moyenne)
       *
       * - {bulletText} = texte formaté
       * - {valueX} = texte non formaté. Par exemple: "1 790.90" versus "1 790.9" ou "90 $" versus "90".
       * - {pourcentageLabel} = nombre arrondi avec symbole de pourcentage
       *
       */
      let nomTooltip = '{categoryY}';
      if (this.graphInfo.tooltipTextBySeries && this.graphInfo.tooltipTextBySeries === true) {
        nomTooltip = series.name;
      }
      if (this.graphInfo.contenuUnitesDansLaBande && this.graphInfo.pourcentageGraph) {
        // si le graphique comporte à la fois un pourcentage et une unité particulière, comme " hab." pour le graphique 11, voici le format attendu: "50 % (538 015 hab.)".
        series.columns.template.tooltipText = `${nomTooltip}: [bold]{pourcentageLabel} ({bulletText} ${this.graphInfo.contenuUnitesDansLaBande})[/]`;
      } else if (this.graphInfo.contenuUnitesDansLaBande === '' && this.graphInfo.pourcentageGraph) {
        // si le contenu de la chaîne d'unités est vide, mais "truthy" (= est défini) et qu'il y a bien présence d'un pourcentage, c'est qu'il faut afficher le nombre (sans unités) et le pourcentage dans l'infobulle
        series.columns.template.tooltipText = `${nomTooltip}: [bold]{pourcentageLabel} ({bulletText})[/]`;
      } else if (this.graphInfo.contenuUnitesDansLaBande && this.graphInfo.pourcentageGraph === false) {
        // s'il y a une unité comme " hab.", mais pas de données en pourcentage; pour le moment, aucun graphique ne correspond à cela, mais si on devait employer des unités à des endroits sans pourcentage, permettra d'afficher tout de même les unités dans l'infobulle sans travail supplémentaire de programmation
        series.columns.template.tooltipText = `${nomTooltip}: [bold]{bulletText} ${this.graphInfo.contenuUnitesDansLaBande}[/]`;
      } else {
        series.columns.template.tooltipText = `${nomTooltip}: [bold]{bulletText}[/]`;
      }

      const valueLabel = series.bullets.push(new am4charts.LabelBullet());

      // création du pourcentage/de la somme de dollars, etc. s'affichant à droite de chaque bande du graphique
      const showSecondary = this.graphInfo.secondaryInGraph === undefined || this.graphInfo.secondaryInGraph;
      if (showPourcentage) {
        if (showSecondary) {
          const valueLabelPourcentage = series.bullets.push(new am4charts.LabelBullet());
          valueLabelPourcentage.label.horizontalCenter = 'right';

          // création du libellé s'affichant dans la bande du graphique à bandes (et non à sa droite)
          if (showDollarSign) {
            valueLabelPourcentage.label.text = '{bulletText}';
          } else {
            valueLabelPourcentage.label.text = '{totalFormatte}';
          }

          // Ajout conditionnel d'un mot à la fin de la bande pour expliciter la nature des chiffres présentés ("hab." pour "habitants", etc.). Voir billet 640 pour plus d'infos à ce sujet. Ne concerne que le graphique 11 au moment de l'écriture de ces lignes, le 2022-03-21, mais potentiellement utilisable pour tous les graphiques à bandes.
          if (this.graphInfo.contenuUnitesDansLaBande) {
            valueLabelPourcentage.label.text += ` ${this.graphInfo.contenuUnitesDansLaBande}`;
          }

          valueLabelPourcentage.label.fontSize = 11;
          valueLabelPourcentage.label.fill = series.fill.alternative;

          valueLabelPourcentage.adapter.add('mask', function (mask: any, target: am4charts.LabelBullet) {
            const dataItem = target.dataItem as am4charts.ColumnSeriesDataItem
            // const circleBullet = target.parent as am4charts.CircleBullet;
            return dataItem.column;
          })
        }
        valueLabel.label.text = '{pourcentageLabel}';
      } else if (showDollarSign) {
        valueLabel.label.text = '{bulletText}';
      } else if (this.graphInfo.contenuUnitesDansLaBande !== undefined && this.graphInfo.contenuUnitesDansLaBande !== '') {
        valueLabel.label.text = '{totalFormatte} ' + this.graphInfo.contenuUnitesDansLaBande;
      } else {
        valueLabel.label.text = '{totalFormatte}';
      }

      valueLabel.label.horizontalCenter = 'left';
      valueLabel.label.dx = 10;
      valueLabel.label.truncate = false;
      valueLabel.label.fontSize = 14;
      // si le label de couleur "mauve" #875692 doit être en gras
      if (this.graphInfo.boldLabels) {
        let boldLabelsSerieName = 'Municipalité';
        if (this.graphInfo.boldLabelsSerieName !== undefined) {
          boldLabelsSerieName = this.graphInfo.boldLabelsSerieName;
        }
        if (series.name === boldLabelsSerieName) {
          valueLabel.label.fontWeight = 'bold';
        }
      }

      if (this.graphInfo.valueDescriptionField) {
        valueLabel.label.text += ` {${this.graphInfo.valueDescriptionField}}`;
      }
      if (this.graphInfo.icons) {
        // debugger;
        const serieBullet = series.bullets.push(new am4charts.CircleBullet()) as am4charts.CircleBullet;
        const serieBulletImage = serieBullet.createChild(am4core.Image);
        // serieBullet.isMeasured = true;
        // serieBulletImage.width = am4core.percent(100);
        // serieBulletImage.height = am4core.percent(100);
        // serieBulletImage.width = 70;
        // serieBulletImage.height = 70;
        // serieBullet.valign = 'middle';
        // serieBullet.align = 'left';
        serieBullet.circle.horizontalCenter = 'left';

        serieBullet.locationX = 1;
        // serieBullet.fill = am4core.color('#42FF33');
        // serieBullet.circle.fill = am4core.color('#FF4C33');
        // serieBulletImage.horizontalCenter = 'left';
        serieBulletImage.verticalCenter = 'middle';
        // serieBullet.circle.radius = 25;
        // serieBullet.circle.radius = 28;
        serieBulletImage.propertyFields.href = 'icon';
        serieBullet.circle.adapter.add('radius', function (mask: any, target: am4core.Circle) {
          const dataItem = target.parent!.dataItem as am4charts.ColumnSeriesDataItem
          return dataItem.column.pixelHeight / 2 - 2;
        })
        serieBullet.adapter.add('mask', function (mask: any, target: am4charts.CircleBullet) {
          const dataItem = target.dataItem as am4charts.ColumnSeriesDataItem
          return dataItem.column;
        })
        // serieBulletImage.adapter.add('mask', function (mask: any, target: am4core.Image) {
        //   const circleBullet = target.parent as am4charts.CircleBullet;
        //   const dataItem = circleBullet.dataItem as am4charts.ColumnSeriesDataItem;
        //   target.height = circleBullet.circle.height + 10;
        //   target.width = circleBullet.circle.height + 10;
        //   return circleBullet.circle;
        // })
        serieBulletImage.adapter.add('height', function (mask: any, target: am4core.Image) {
          const circleBullet = target.parent as am4charts.CircleBullet;
          const dataItem = circleBullet.dataItem as am4charts.ColumnSeriesDataItem;
          // target.height = dataItem.column.pixelHeight + 10;
          // target.width = dataItem.column.pixelHeight + 10;
          const radius = circleBullet.circle.radius as number;
          return radius * 2;
        })
        // serieBulletImage.adapter.add('mask', function (mask: any, target: am4core.Image) {
        //   const circleBullet = target.parent as am4charts.CircleBullet;
        //   const dataItem = circleBullet.dataItem as am4charts.ColumnSeriesDataItem;
        //   console.log(dataItem.categoryY, dataItem.valueX, target.height, target.width, dataItem.column.pixelHeight)
        //   target.height = dataItem.column.pixelHeight + 10;
        //   target.width = dataItem.column.pixelHeight + 10;
        //   return circleBullet.circle;
        // })
      }

      series.columns.template.height = am4core.percent(90);
      console.log(series.columns.template)
    },
    /**
     * Création dans les graphiques à bandes des longues barres verticales.
     * Exemple de graphique concerné: Taux de taxation par 100 $ de RFU
     * Chemin d'accès: ===> onglet profil financier ===> Taux de taxation par 100 $ de RFU
     */
    createMoyenneRange (quelNom: string, quelGraph: string, quelleCouleur: string) {
      const range = this.valueAxis.axisRanges.create();
      const moyenneArray = this.getMoyenneArray();

      /**
       * La première ligne de code ci-dessous va chercher la chaîne correspondant au nom de la moyenne, qui peut varier d'un type de graphique à l'autre.
       * La deuxième ligne de code récupère ce nom et s'en sert pour chercher le nom de la propriété.
       */
      const moyenne = moyenneArray[0][quelGraph];
      let nbDecimalMoyenne = 5;
      if (this.graphInfo.decimalInMoyenne !== undefined) {
        nbDecimalMoyenne = this.graphInfo.decimalInMoyenne;
      }
      const moyenneFormate = this.graphDataConverter.formatNombreAAfficher(moyenne, nbDecimalMoyenne);
      const texteAafficher = `${quelNom} : ${moyenneFormate}`;
      const color = am4core.color(quelleCouleur);
      range.value = moyenne;
      range.grid.strokeWidth = 3;
      range.grid.strokeOpacity = 1;
      range.grid.stroke = color;
      range.label.fill = range.grid.stroke;
      range.grid.above = true;

      /**
       * Crée et réglage d'une variable pour la préparation de la deuxième puce.
       * Note: range2.value sert ici à placer la puce/le cercle sur les lignes verticales
       */
      const range2 = this.valueAxis.axisRanges.create();
      range2.value = moyenne;

      /**
       * Génère les puces à l'extrémité de la ligne jaune.
       */
      function generePuce (whatRange: any, whatPosition: number) {
        whatRange.bullet = new am4core.Circle();
        whatRange.bullet.width = 15;
        whatRange.bullet.height = 11;
        whatRange.bullet.fill = color;
        whatRange.bullet.tooltipText = `${texteAafficher}`;
        whatRange.bullet.dy = whatPosition;
      }
      if (this.graphInfo.showMoyenneInLegend) {
        const legendItem = range as any;
        legendItem.name = quelNom;
        legendItem.fill = color;
        legendItem.stroke = color;
        this.chart.legend.data.push(legendItem);
      }
      generePuce(range, 0);
    },
    /**
     * Détermine si toutes les MRC et régions ont une moyenne identique ou non.
     * Si ce n'est pas le cas, on veut cacher les lignes.
     * En d'autres termes: les lignes de moyenne pour MRC et régions devraient toujours être verticales, sans aucune diagonale.
     */
    checkIfAllAveragesSame () {
      const moyenneArray = this.getMoyenneArray();
      const arrMoyMrc = [];
      const arrMoyRegion = [];

      /**
       * Les variables ci-dessous permettent de récupérer les moyennes pour la MRC et la région, qui peuvent varier d'un type de graphique à l'autre, d'où le besoin de récupérer dynamiquement le nom des chaînes.
       */
      const elementAComparerMrc = this.graphInfo.moyennes!.mrc as string;
      const elementAComparerRegion = this.graphInfo.moyennes!.region as string;

      for (let i = 0; i < moyenneArray.length; i++) {
        arrMoyMrc.push(moyenneArray[i][elementAComparerMrc]);
        arrMoyRegion.push(moyenneArray[i][elementAComparerRegion]);
      }

      /**
       * Si la longueur est de 1, c'est que tous les éléments de l'array étaient identiques, donc la fonction retourne "true".
       */
      return {
        mrc: [...new Set(arrMoyMrc)].length === 1,
        region: [...new Set(arrMoyRegion)].length === 1
      };
    },
    /**
     * Choisir Laval, Desbiens et Métabéchouan, puis activer/désactiver Laval pour voir affichage conditionnel de deux des barres verticales.
     */
    createMoyennes () {
      if (this.graphInfo.moyennes !== undefined) {
        const areAveragesSame = this.checkIfAllAveragesSame();

        const moyenneMrcGraph = this.graphInfo.moyennes.mrc;
        const moyenneRegionGraph = this.graphInfo.moyennes.region;
        const moyenneGlobaleGraph = this.graphInfo.moyennes.globale;
        const moyenneMemeTailleGraph = this.graphInfo.moyennes.memeTaille;

        if (moyenneMrcGraph !== undefined && areAveragesSame.mrc === true) {
          this.createMoyenneRange('Moyenne mrc', moyenneMrcGraph, '#CE60C1');
        }
        if (moyenneRegionGraph !== undefined && areAveragesSame.region === true) {
          this.createMoyenneRange('Moyenne région', moyenneRegionGraph, '#A367DC');
        }
        if (moyenneGlobaleGraph !== undefined) {
          this.createMoyenneRange('Moyenne provinciale', moyenneGlobaleGraph, '#000000');
        }
        if (moyenneMemeTailleGraph !== undefined) {
          this.createMoyenneRange('Moyenne même taille', moyenneMemeTailleGraph, '#DCD2FF');
        }
      }
    }
  },
  mounted () {
    this.chartData = this.data.data;
    this.generateGraphs();
    this.chart.invalidate();
  },
  created () {
    am4core.useTheme(am4themesMyTheme);
    // am4core.useTheme(am4themesKelly);
  }
})
