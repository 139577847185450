
import { defineComponent } from 'vue'
import MenubarMaison from '../plugins/MenubarMaison.vue'

export default defineComponent({
  name: 'Header',
  components: {
    MenubarMaison
  },
  data () {
    return {
      items: [
        {
          label: localStorage.getItem('email'),
          icon: '',
          items: [
            {
              label: 'Déconnexion',
              icon: 'pi pi-power-off',
              command: (event: any) => {
                this.$store.dispatch('auth/logout').then(() => {
                  this.$router.push('/')
                })
              }
            }
          ]
        }
      ]
    }
  }
})
