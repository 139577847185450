import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f805be00"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "error-box"
}
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.errorMessage.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionProp.reponsesPossibles, (reponse) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "margin-entre-reponses-questionnaire",
        key: reponse.texteReponse
      }, [
        _createVNode(_component_Checkbox, {
          onChange: _ctx.onValueChange,
          name: reponse.texteReponse,
          value: reponse.texteReponse,
          modelValue: _ctx.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event))
        }, null, 8, ["onChange", "name", "value", "modelValue"]),
        _createElementVNode("label", {
          for:  reponse.texteReponse
        }, _toDisplayString(reponse.texteReponse), 9, _hoisted_2)
      ]))
    }), 128))
  ], 64))
}