
import { defineComponent, inject, PropType, Ref } from 'vue'
import Dropdown from 'primevue/dropdown';
import { EndroitDTO } from '../DTO/EndroitDTO'
import { EndroitSelectionHelper } from '@/helpers/EndroitSelectionHelper';
import { EndroitType } from '@/DTO/EndroitType';
export default defineComponent({
  name: 'RechercheEndroitTexte',
  props: {
    endroitsData: {
      type: Array as PropType<EndroitDTO[]>,
      required: true
    }
  },
  components: {
    Dropdown
  },
  setup () {
    const endroits = inject('endroits') as Ref<Array<EndroitDTO>>
    const endroitSelectionHelper = inject('endroitSelectionHelper') as Ref<EndroitSelectionHelper>
    return {
      endroits,
      endroitSelectionHelper
    }
  },
  data () {
    return {
      regionSelectionnee: null as EndroitDTO | null,
      mrcSelectionnee: null as EndroitDTO | null,
      municipaliteSelectionnee: null as EndroitDTO | null,
      dropdownDataRegion: new Array<EndroitDTO>(),
      dropdownDataMRC: new Array<EndroitDTO>(),
      dropdownDataMunicipalite: new Array<EndroitDTO>()
    }
  },
  watch: {
    '$route' (to, from) {
      this.setDropdownData();
    }
  },
  methods: {
    setDropDownDataRegion () {
      this.dropdownDataRegion = this.getSortedEndroitArray(this.endroitsData.filter(endr => {
        return !this.endroits.includes(endr) && (endr.typeEndroit === EndroitType.Region)
      }));
      this.regionSelectionnee = null;
      this.mrcSelectionnee = null;
      this.municipaliteSelectionnee = null;
    },
    setDropDownDataMRC (hasRegionSelected:boolean) {
      if (hasRegionSelected) {
        this.dropdownDataMRC = this.getSortedEndroitArray(this.endroitsData.filter(endr => {
          return !this.endroits.includes(endr) && endr.typeEndroit === EndroitType.MRC && endr.idParent === this.regionSelectionnee?._id
        }));
      } else {
        this.dropdownDataMRC = this.getSortedEndroitArray(this.endroitsData.filter(endr => {
          return !this.endroits.includes(endr) && endr.typeEndroit === EndroitType.MRC
        }))
      }
      this.mrcSelectionnee = null;
      this.municipaliteSelectionnee = null;
    },
    setDropDownDataMunicipalite () {
      this.dropdownDataMunicipalite = this.getSortedEndroitArray(this.endroitsData.filter(endr => {
        return !this.endroits.includes(endr) && endr.typeEndroit === EndroitType.municipalite && endr.idParent === this.mrcSelectionnee?._id
      }));
      this.municipaliteSelectionnee = null;
    },
    async ajouterMunicipalite () {
      this.$loading.startLoading();
      const drop = this.$refs.dropdown as any;
      drop.filterValue = null;
      let endroitSelectionnee: EndroitDTO | null = null;
      if (this.municipaliteSelectionnee != null) {
        endroitSelectionnee = this.municipaliteSelectionnee;
      } else if (this.mrcSelectionnee != null) {
        endroitSelectionnee = this.mrcSelectionnee;
      } else if (this.regionSelectionnee != null) {
        endroitSelectionnee = this.regionSelectionnee;
      }
      if (endroitSelectionnee !== null) {
        this.endroitSelectionHelper.addEndroitAndHandleType(endroitSelectionnee, this.$toast, this.$store).then(() => {
          this.$emit('onEndroitUpdated');
        });
      }
    },
    setDropdownData () {
      this.setDropDownDataRegion();
      this.setDropDownDataMRC(false);
      this.setDropDownDataMunicipalite();
    },
    getSortedEndroitArray (endroits: EndroitDTO[]) {
      return endroits.sort((a, b) => {
        if (a.NOM_GEO < b.NOM_GEO) {
          return -1
        } else if (a.NOM_GEO > b.NOM_GEO) {
          return 1
        } else {
          return 0
        }
      });
    }
  },
  mounted () {
    this.setDropdownData();
    this.$loading.stopLoading()
  }
})

