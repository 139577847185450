import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a47c6c1c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "menuLateral",
  class: "sidenav"
}
const _hoisted_2 = {
  key: 0,
  class: "onglet"
}
const _hoisted_3 = ["href", "title"]
const _hoisted_4 = { class: "conteneur-icone-texte" }
const _hoisted_5 = {
  key: 0,
  class: "p-panelmenu-icon pi pi-chevron-right mon-chevron"
}
const _hoisted_6 = { class: "barre-laterale-texte" }
const _hoisted_7 = {
  key: 1,
  class: "onglet"
}
const _hoisted_8 = { class: "conteneur-icone-texte" }
const _hoisted_9 = {
  key: 0,
  class: "p-panelmenu-icon pi pi-chevron-right mon-chevron"
}
const _hoisted_10 = { class: "barre-laterale-texte" }
const _hoisted_11 = {
  key: 2,
  class: "onglet"
}
const _hoisted_12 = { class: "conteneur-icone-texte" }
const _hoisted_13 = {
  key: 0,
  class: "p-panelmenu-icon pi pi-chevron-right mon-chevron"
}
const _hoisted_14 = { class: "barre-laterale-texte" }
const _hoisted_15 = { class: "conteneur-icone-texte" }
const _hoisted_16 = { class: "barre-laterale-texte" }
const _hoisted_17 = {
  key: 0,
  class: "p-panelmenu-icon pi pi-chevron-right mon-chevron"
}
const _hoisted_18 = { class: "sous-onglet" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("a", {
        href: "javascript:;",
        class: "close-btn",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (this.closeSideNavButton()))
      }, "×"),
      _createElementVNode("aside", null, [
        _createElementVNode("nav", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuComputed, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item,
              class: "conteneur-onglets-sous-onglets",
              ref_for: true,
              ref: _ctx.addOnglets
            }, [
              (item.externalUrl)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("a", {
                      href:  item.externalUrl,
                      target: "_blank",
                      rel: "noopener noreferrer",
                      title: item.label
                    }, [
                      _createElementVNode("div", _hoisted_4, [
                        (item.items)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_5))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", {
                          class: _normalizeClass(`barre-laterale-icone ${item.icon}`)
                        }, null, 2),
                        _createElementVNode("span", null, [
                          _createElementVNode("div", _hoisted_6, _toDisplayString(item.label), 1)
                        ])
                      ])
                    ], 8, _hoisted_3)
                  ]))
                : (item.url)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(_component_router_link, {
                        to: { name:'Rechercher-Endroit', query: { keepEndroits: true }},
                        title: item.label
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_8, [
                            (item.items)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_9))
                              : _createCommentVNode("", true),
                            _createElementVNode("span", {
                              class: _normalizeClass(`barre-laterale-icone ${item.icon}`)
                            }, null, 2),
                            _createElementVNode("span", null, [
                              _createElementVNode("div", _hoisted_10, _toDisplayString(item.label), 1)
                            ])
                          ])
                        ]),
                        _: 2
                      }, 1032, ["title"])
                    ]))
                  : (item.name === 'tout')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _createVNode(_component_router_link, {
                          to: { name: 'Home', params: { section: item.name }, query: { keepEndroits: true }},
                          title: item.label
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_12, [
                              (item.items)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_13))
                                : _createCommentVNode("", true),
                              _createElementVNode("span", {
                                class: _normalizeClass(`barre-laterale-icone ${item.icon}`)
                              }, null, 2),
                              _createElementVNode("span", null, [
                                _createElementVNode("div", _hoisted_14, _toDisplayString(item.label), 1)
                              ])
                            ])
                          ]),
                          _: 2
                        }, 1032, ["to", "title"])
                      ]))
                    : (_openBlock(), _createElementBlock("div", {
                        key: 3,
                        class: "onglet",
                        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.gereClicOnglet && _ctx.gereClicOnglet(...args)))
                      }, [
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("span", {
                            class: _normalizeClass(`barre-laterale-icone ${item.icon}`)
                          }, null, 2),
                          _createElementVNode("span", null, [
                            _createElementVNode("div", _hoisted_16, _toDisplayString(item.label), 1)
                          ]),
                          (item.items)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_17))
                            : _createCommentVNode("", true)
                        ])
                      ])),
              (item.items !== undefined)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 4 }, _renderList(item.items, (subItem) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: subItem,
                      ref_for: true,
                      ref: _ctx.addSousOnglets
                    }, [
                      _createVNode(_component_router_link, {
                        to: { name: 'Home', params: { section: item.name }, query: { keepEndroits: true }, hash: '#' + subItem.to.params.idListData },
                        title: subItem.label,
                        class: _normalizeClass('lien-sous-onglet' + (subItem.isActive ? ' link-active' : ''))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_18, _toDisplayString(subItem.label), 1)
                        ]),
                        _: 2
                      }, 1032, ["to", "title", "class"])
                    ]))
                  }), 128))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ])
      ])
    ], 512),
    _createElementVNode("span", {
      class: "hamburger",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (this.openSideNavButton()))
    }, "☰")
  ], 64))
}