import data from '../configs/TranslatorValues.json';
export class ValueTranslator {
    private translatedFields = new Map<string, string>();

    public constructor () {
      data.forEach((i) => {
        this.translatedFields.set(i.oldValue, i.newValue);
      });
    }

    public translate (valueToTranslate: string): string {
      if (this.translatedFields.has(valueToTranslate)) {
        return this.translatedFields.get(valueToTranslate)!;
      }
      return valueToTranslate;
    }
}
