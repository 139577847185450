import axios from 'axios'
import { Module } from 'vuex'
const auth: Module<any, any> = {
  namespaced: true,
  state: {
  },
  getters: {
    isLoggedIn: () => localStorage.getItem('token') !== null,
    email: () => localStorage.getItem('email') !== null
  },
  actions: {
    logout ({ commit }) {
      commit('clearUserData')
    },
    login ({ commit }, credentials) {
      return axios
        .post(process.env.VUE_APP_API_URL + '/login', credentials)
        .then((response: any) => {
          localStorage.setItem('token', response.data.token)
          axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`
        })
    },
    requestUserDetails ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.request({
          url: '/details',
          method: 'get',
          baseURL: process.env.VUE_APP_API_URL,
          headers: {
            Authorization: axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
          }
        }).then((response: any) => {
          localStorage.setItem('categorie', response.data.user.categorie_id)
          resolve(response)
        }).catch((err: any) => {
          reject(err)
        })
      })
    },
    register (context, informations) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/register',
          method: 'post',
          baseURL: process.env.VUE_APP_API_URL,
          data: informations
        }).then((response: any) => {
          resolve(response)
        }).catch((err: any) => {
          reject(err)
        })
      })
    },
    requestResetPassword ({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_URL + '/passwordreset/request', credentials)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      })
    },
    resetPassword ({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_URL + '/passwordreset', credentials)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      })
    }
    // updateUser(context, informations) {
    //   return new Promise((resolve, reject) => {
    //     axios({
    //       url: '/update_user',
    //       method: 'post',
    //       baseURL: process.env.VUE_APP_API_URL,
    //       headers: {
    //         Authorization: axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
    //       },
    //       data: informations
    //     }).then((response: any) => {
    //       resolve(response)
    //     }).catch((err: any) => {
    //       reject(err)
    //     })
    //   })
    // },
    // updatePassword(context, informations) {
    //   return new Promise((resolve, reject) => {
    //     axios({
    //       url: '/update_password',
    //       method: 'post',
    //       baseURL: process.env.VUE_APP_API_URL,
    //       headers: {
    //         Authorization: axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
    //       },
    //       data: informations
    //     }).then((response: any) => {
    //       resolve(response)
    //     }).catch((err: any) => {
    //       reject(err)
    //     })
    //   })
    // },
  },

  mutations: {
    clearUserData () {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('email');
    }
  }
}

export default auth
