import { DescriptionGraphiqueDTO } from '@/DTO/DescriptionGraphiqueDTO';
import { GraphConverterDataDTO } from '@/DTO/GraphConverterDataDTO';
import { GraphErrorDTO } from '@/DTO/GraphErrorDTO';
import { RecensementDataItem } from '@/DTO/RecensementDataItem';
import { ValueTranslator } from '@/traduction/ValueTranslator';
import IconsConfig from '@/configs/icons.json';
import { IconListItem } from '@/DTO/Icons/IconListItem';
import { IconList } from '@/DTO/Icons/IconList';
import { IconDescription } from '@/DTO/Icons/IconDescription';

export class GraphDataConverter {
  private translator: ValueTranslator;
  private iconList: IconList;

  public constructor (translator: ValueTranslator) {
    this.translator = translator;
    this.iconList = IconsConfig;
  }

  private getIfTotalIsPrimaryValue (graphInfo: DescriptionGraphiqueDTO): boolean {
    return graphInfo.pourcentageGraph === undefined || graphInfo.pourcentageGraph === false;
  }

  /**
  * Ajoute le champ bulletText a l'item
  * @param {Array<any>} data - item a traiter
  * @param {DescriptionGraphiqueDTO} graphInfo - Les infos du graph selons le fichier de config
  * @returns {Array<string>} l'item avec le champ
  */
  private addBulletTextToData (data: any, graphInfo: DescriptionGraphiqueDTO) {
    // nb de décimales par défaut: 2
    let decimalInPrimaryData = 2;

    // si le nb comporte des décimales, ajuster la variable decimalDemande en conséquence
    if (graphInfo.decimalInData !== undefined) {
      decimalInPrimaryData = graphInfo.decimalInData;
    }
    let decimalInSecondaryData = decimalInPrimaryData;

    if (graphInfo.decimalInSecondaryData !== undefined) {
      decimalInSecondaryData = graphInfo.decimalInSecondaryData;
    }

    let decimalInTotal = decimalInPrimaryData;
    let decimalInPourcentage = decimalInPrimaryData;

    const isTotalPrymaryValue = this.getIfTotalIsPrimaryValue(graphInfo)
    if (isTotalPrymaryValue) {
      decimalInPourcentage = decimalInSecondaryData;
    } else {
      decimalInTotal = decimalInSecondaryData;
    }

    // Formatage du nombre selon sa nature (pourcentage, somme de dollars, voire autre chose).
    const total = data.total;
    let nbFormatte = graphInfo.totalNonNumerique ? total : (data.pourcentage ? this.formatNombreAAfficher(total, 0) : this.formatNombreAAfficher(parseFloat(total.toFixed(decimalInTotal)), decimalInTotal));
    if (graphInfo.labelCompresse) {
      nbFormatte = this.formatBigNumber(total);
    }
    // Ajout du total formaté dans data pour affichage.
    data.totalFormatte = nbFormatte;

    /**
     * ajout des symboles $ et % selon la situation
     */
    if (data.pourcentage !== undefined || graphInfo.showDollarSign) {
      let finaleChaine = '';

      /**
       * S'il y a pourcentage, formatage du pourcentage et ajout dans le data.
       *
       * Attention! "data.pourcentage === 0" sert ici à tenir compte du cas de figure où un pourcentage serait de zéro. Comme zéro est "falsy", la condition ci-dessous n'était pas prise en compte. Résultat, on pouvait se retrouver avec des valeurs de "undefined" plutôt que de "0 %".
       *
       * Ce phénomène pouvait par exemple se produire dans le tableau simplifié ou standard, pour cette requête: http://ordicwm:8080/home/12?endroits=618d4aef0bfeb2d99f918744,618d42120bfeb2d99f4a7a96.
       */
      if (data.pourcentage || data.pourcentage === 0) {
        // Copie pour ne pas modifier l'original
        let pourcentage = data.pourcentage;
        // fix temporaire
        if (data.pourcentage > 100) {
          pourcentage = 100;
        }
        pourcentage = pourcentage.toFixed(decimalInPourcentage);
        // Ajout du pourcentage formaté pour affichage
        data.pourcentageLabel = pourcentage + ' %';
      }

      // création de la finale de la chaîne
      // si graphInfo.showDollarSign est défini, c'est qu'on a une valeur en dollars à afficher
      if (graphInfo.showDollarSign) {
        finaleChaine = ' $';
      }

      data.bulletText = nbFormatte + finaleChaine;
    } else {
      data.bulletText = nbFormatte;
    }

    if (graphInfo.moyennes) {
      if (graphInfo.moyennes.globale && graphInfo.moyennes.globale in data) {
        let primaryKeyPrefix = '';
        if (graphInfo.tableauGroupeInfo) {
          primaryKeyPrefix = isTotalPrymaryValue ? 'total-' : 'pourcentage-'
          if (graphInfo.tableauGroupeInfo.champMoyenneGlobalAlternatif && graphInfo.tableauGroupeInfo.champMoyenneGlobalAlternatif in data) {
            const secondaryKeyPrefix = isTotalPrymaryValue ? 'pourcentage-' : 'total-';
            data[secondaryKeyPrefix + 'Moyenne provinciale'] = this.getNombreFormated(data[graphInfo.tableauGroupeInfo.champMoyenneGlobalAlternatif], graphInfo, !isTotalPrymaryValue, decimalInSecondaryData);
          }
        }
        data[primaryKeyPrefix + 'Moyenne provinciale'] = this.getNombreFormated(data[graphInfo.moyennes.globale], graphInfo, isTotalPrymaryValue, decimalInPrimaryData);
      }
    }
    return data;
  }

  private getNombreFormated (nombre: number, graphinfo: DescriptionGraphiqueDTO, isTotalPrymaryValue: boolean, decimalInPrimaryData: number): string {
    // debugger;
    let nbFormate = this.formatNombreAAfficher(nombre, decimalInPrimaryData);
    if (isTotalPrymaryValue && graphinfo.showDollarSign) {
      nbFormate += ' $';
    }
    if (!isTotalPrymaryValue) {
      nbFormate += ' %';
    }
    return nbFormate;
  }

  /**
  * Retourne la liste d'erreurs avec la validation
  * @param {DescriptionGraphiqueDTO} graphInfo - Les infos du graph selons le fichier de config
  * @param {RecensementDataItem} data - item a traiter
  * @returns {Array<GraphErrorDTO>} liste d'erreurs
  */
  private getErrorsIfNotValid (graphInfo: DescriptionGraphiqueDTO, data: RecensementDataItem): Array<GraphErrorDTO> {
    const erreurs = new Array<GraphErrorDTO>();
    let totalFieldName = 'total';
    let themeFieldName = 'theme';
    if (graphInfo.totalField !== undefined) {
      totalFieldName = graphInfo.totalField;
    }
    if (graphInfo.themeField !== undefined) {
      themeFieldName = graphInfo.themeField;
    }
    if (!graphInfo.totalNonNumerique && typeof data[totalFieldName] !== 'number') {
      erreurs.push({
        errorType: 'Valeur incompatible',
        endroitData: data.endroit!,
        theme: data[themeFieldName],
        value: data[totalFieldName]
      })
    }
    return erreurs;
  }

  private addIconToRecensementItem (data: RecensementDataItem, graphInfo: DescriptionGraphiqueDTO) {
    if (graphInfo.icons) {
      let nom = this.iconList.default.nom;
      let iconField = graphInfo.themeField || 'theme';
      if (graphInfo.iconField) {
        iconField = graphInfo.iconField;
      }
      const iconName = data[iconField];
      let hasFoundIcon = false;
      if (graphInfo.id in this.iconList) {
        const graphIcons = this.iconList[graphInfo.id] as IconListItem;
        if (iconName in graphIcons) {
          nom = graphIcons[iconName].nom;
          hasFoundIcon = true;
        }
      }
      if (!hasFoundIcon && iconName in this.iconList.byName) {
        nom = this.iconList.byName[iconName].nom;
        hasFoundIcon = true;
      }
      data.icon = '/GraphIcons/' + nom;
    }
    return data;
  }

  /**
  * Converti les data des recensements reçu du serveur pour retourner une map de data par endroit
  * @param {DescriptionGraphiqueDTO} graphInfo - Les infos du graph selons le fichier de config
  * @param {Array<any>} graphData - La liste des données à convertir
  * @param {Array<string>} endroitIDs - La liste des endroits à garder
  * @returns {Map<string, Array<any>>} les données sous format: key = endroitId, value = dataList
  */
  public convertData (graphInfo: DescriptionGraphiqueDTO, graphData: Array<any>, endroitIDs: Array<string>): Map<string, GraphConverterDataDTO> {
    const newData = new Map<string, GraphConverterDataDTO>();
    // Si la clé seriesBy existe dans graphiques.json.
    const noSeriesKey = 'noKey';
    if (graphInfo.noSeries) {
      newData.set(noSeriesKey, { data: new Array<RecensementDataItem>(), errors: new Array<GraphErrorDTO>() });
    } else if (graphInfo.seriesBy === undefined) {
      endroitIDs.forEach((endroitId: string) => {
        newData.set(endroitId, { data: new Array<RecensementDataItem>(), errors: new Array<GraphErrorDTO>() });
      });
    }
    const graphDataWithNomEndroits = this.addNomEndroitToDataList(graphData);
    const graphDataWithDuplicatesAdded = this.getGraphDataConsideringListeChampsSeries(graphDataWithNomEndroits, graphInfo);
    graphDataWithDuplicatesAdded.forEach((dataItem: any) => {
      const anneeRecensement = dataItem.anneeRecensement as string;
      const isDataByEndroit = dataItem.endroit !== undefined
      let key = isDataByEndroit ? dataItem.endroit._id : noSeriesKey;
      // Si évalue par année de recensement, validation + ajout année dans les valeurs possibles
      if (graphInfo.seriesBy === 'anneeRecensement') {
        key = anneeRecensement;
        if (!newData.has(key)) {
          newData.set(key, { data: new Array<RecensementDataItem>(), errors: new Array<GraphErrorDTO>() });
        }
      }
      // Si les informations demandées sont disponibles
      if (!isDataByEndroit || endroitIDs.includes(dataItem.endroit._id)) {
        // Logique principale
        // Met les données utilisables individuellement
        const newDataItem = this.setThemeAndTotalOnItemAndHisChildren(dataItem, graphInfo);
        // Va chercher la couche de données cible (couche 2 = parent -> enfant -> cible)
        const dataWithGoodLayer = this.getDataConsideringLayerToGetTo(newDataItem, graphInfo.layerToGetInfo);
        if (Array.isArray(dataWithGoodLayer)) {
          dataWithGoodLayer.forEach((item: RecensementDataItem) => {
            let trueKey = key;
            if (graphInfo.seriesBy !== undefined) {
              trueKey = item[graphInfo.seriesBy];
            }
            if (!newData.has(trueKey)) {
              newData.set(trueKey, { data: new Array<RecensementDataItem>(), errors: new Array<GraphErrorDTO>() });
            }
            const erreurs = this.getErrorsIfNotValid(graphInfo, item);
            if (erreurs.length > 0) {
              newData.get(trueKey)!.errors.push(...erreurs);
            } else {
              const dataWithAnneeString = this.getDataWithAnneeAsString(item);
              const dataWithBulletText = this.addBulletTextToData(dataWithAnneeString, graphInfo);
              const dataWithEndroitNom = this.addNomEndroitToData(dataWithBulletText);
              const dataWithIcon = this.addIconToRecensementItem(dataWithEndroitNom, graphInfo);
              if (graphInfo.globalTotalField && graphInfo.globalTotalField.field === dataWithIcon.theme) {
                newData.get(trueKey)!.globalTotal = dataWithIcon;
                if (!graphInfo.globalTotalField.hideInGraph) {
                  newData.get(trueKey)!.data.push(dataWithIcon);
                }
              } else {
                newData.get(trueKey)!.data.push(dataWithIcon);
              }
            }
          });
        } else {
          if (graphInfo.seriesBy !== undefined) {
            key = dataItem[graphInfo.seriesBy];
            if (!newData.has(key)) {
              newData.set(key, { data: new Array<RecensementDataItem>(), errors: new Array<GraphErrorDTO>() });
            }
          }
          const erreurs = this.getErrorsIfNotValid(graphInfo, dataWithGoodLayer);
          if (erreurs.length > 0) {
            newData.get(key)!.errors.push(...erreurs);
          } else {
            const dataWithAnneeString = this.getDataWithAnneeAsString(dataWithGoodLayer);
            const dataWithBulletText = this.addBulletTextToData(dataWithAnneeString, graphInfo);
            const dataWithEndroitNom = this.addNomEndroitToData(dataWithBulletText);
            const dataWithIcon = this.addIconToRecensementItem(dataWithEndroitNom, graphInfo);
            if (graphInfo.globalTotalField && graphInfo.globalTotalField.field === dataWithIcon.theme && graphInfo.globalTotalField.hideInGraph) {
              newData.get(key)!.globalTotal = dataWithIcon;
              if (!graphInfo.globalTotalField.hideInGraph) {
                newData.get(key)!.data.push(dataWithIcon);
              }
            } else {
              newData.get(key)!.data.push(dataWithIcon);
            }
          }
        }
      }
    });
    return newData;
  }

  /**
  * Duplique des entrées selon la valeur de listeChampsSeries
  * @param {Array<RecensementDataItem>} graphData - liste de données
  * @param {DescriptionGraphiqueDTO} graphInfo - descrip^tion du graphique
  * @returns {Array<RecensementDataItem>} liste de données avec duplicats
  */
  private getGraphDataConsideringListeChampsSeries (graphData: Array<RecensementDataItem>, graphInfo: DescriptionGraphiqueDTO) {
    const newGraphData = graphData
    if (graphInfo.listFieldsSeries) {
      let themeField = 'theme'
      let totalField = 'total'
      if (graphInfo.themeField) {
        themeField = graphInfo.themeField
      }
      if (graphInfo.totalField) {
        totalField = graphInfo.totalField
      }
      graphData.forEach(dataItem => {
        graphInfo.listFieldsSeries!.forEach(champ => {
          const newDataItem = { ...dataItem };
          newDataItem[totalField] = newDataItem[champ.field];
          if ('displayField' in champ && 'displayName' in champ) {
            const displayField = champ.displayField as string;
            newDataItem.theme = champ.displayName + ' (' + newDataItem[displayField] + ')';
          } else if ('displayName' in champ) {
            newDataItem.theme = champ.displayName;
          } else {
            const displayField = champ.displayField as string;
            newDataItem.theme = newDataItem[displayField];
          }
          newGraphData.push(newDataItem)
        })
      });
    }
    return newGraphData;
  }

  /**
    * Ajoute le champ nomEndroit a la liste d'items
    * @param {Array<RecensementDataItem>} dataList - la liste d'items à traiter
    * @returns {Array<RecensementDataItem>} la liste d'items avec les champs
    */
  private addNomEndroitToDataList (dataList: Array<RecensementDataItem>): Array<RecensementDataItem> {
    const dataWithEndroitNom = dataList.map(dataItem => this.addNomEndroitToData(dataItem));
    return dataWithEndroitNom;
  }

  /**
  * Ajoute le champ nomEndroit a l'item
  * @param {RecensementDataItem} data - item a traiter
  * @returns {RecensementDataItem} l'item avec le champ
  */
  private addNomEndroitToData (data: RecensementDataItem): RecensementDataItem {
    const itemToReturn = { ...data } as RecensementDataItem;
    if (data.endroit) {
      itemToReturn.nomEndroit = data.endroit.NOM_GEO;
      if (data.endroit?.endroitParent) {
        if (data.endroit.NOM_GEO === data.endroit.endroitParent.NOM_GEO) {
          itemToReturn.nomMRC = 'MRC ' + data.endroit.endroitParent.NOM_GEO;
        } else {
          itemToReturn.nomMRC = data.endroit.endroitParent.NOM_GEO;
        }
        if (data.endroit.endroitParent.endroitParent) {
          itemToReturn.nomRegion = data.endroit.endroitParent.endroitParent.NOM_GEO;
        }
      }
      if (data.endroit?.tailleCategorieNom) {
        itemToReturn.tailleCategorieNom = data.endroit.tailleCategorieNom;
      }
    }
    return itemToReturn;
  }

  /**
  * Retourne les information des enfants selon la layer demandée
  * @param {any} data - dataItem à traiter
  * @param {number} layerRemaining - combien on doit descendre dans les couche des enfants
  * @returns {any | Array<any>} les données sous forme de liste ou d'item individuel (individuel si layerRemaining = 0)
  */
  private getDataConsideringLayerToGetTo (data: any, layerRemaining: number) {
    if (layerRemaining > 0 && 'enfants' in data) {
      const newItems = new Array<any>();
      data.enfants.forEach((childItem: any) => {
        newItems.push(this.getDataConsideringLayerToGetTo(childItem, layerRemaining - 1))
      });
      return data.enfants;
    } else {
      return data;
    }
  }

  /**
  * Retourne les information avec les années en string
  * @param {any} data - dataItem à traiter
  * @param {number} layerRemaining - combien on doit descendre dans les couche des enfants
  * @returns {any | Array<any>} les données retournées
  */
  private getDataWithAnneeAsString (data: any) {
    if ('anneeRecensement' in data) {
      data.anneeRecensement = data.anneeRecensement.toString();
    }
    return data;
  }

  /**
  * Ajoute les champs theme et total à tous les items (y compris les enfants)
  * @param {any} item - dataItem à traiter
  * @param {DescriptionGraphiqueDTO} graphInfo - Les infos du graph selons le fichier de config
  * @returns {any} les donnes avec les champs dedans
  */
  private setThemeAndTotalOnItemAndHisChildren (item: any, graphInfo: DescriptionGraphiqueDTO) {
    if (item.theme === undefined) {
      item.theme = graphInfo.XAxixName;
    }
    if (graphInfo.totalField !== undefined && !('total' in item)) {
      let totalValue = item[graphInfo.totalField];
      if (totalValue === '') {
        totalValue = 0;
      }
      item.total = totalValue;
    }
    if ('enfants' in item) {
      const newEnfants = new Array<any>();
      item.enfants.forEach((itemEnfant: any) => {
        newEnfants.push(this.setThemeAndTotalOnItemAndHisChildren(itemEnfant, graphInfo));
      });
      item.enfants = newEnfants;
    }
    return item;
  }

  /**
  * Traduit les valeurs des items selon les traductions fournis dans le fichier de config
  * @param {Array<any>} dataList - Liste d'items a traiter
  * @returns {Array<any>} les données traduitent
  */
  public translateValuesOfDataList (dataList: Array<any>, graphInfo: DescriptionGraphiqueDTO) {
    return dataList.map((item: any) => {
      const newObject: any = {};
      Object.entries(item).forEach((entry: [string, any]) => {
        const fieldName = entry[0] as string;
        let value = entry[1];
        if (fieldName === 'enfants') {
          value = this.translateValuesOfDataList(value, graphInfo);
        }
        if (typeof value === 'string') {
          let valueToTranslate = value;
          if (graphInfo.valeurChampsThemes !== undefined) {
            let themeFieldName = 'theme'
            if (graphInfo.themeField !== undefined) {
              themeFieldName = graphInfo.themeField;
            }
            if (themeFieldName === fieldName) {
              valueToTranslate = graphInfo.valeurChampsThemes;
            }
          }
          newObject[fieldName] = this.translator.translate(valueToTranslate);
        } else {
          newObject[fieldName] = value;
        }
      });
      return newObject;
    });
  }

  /**
  * Va chercher la valeur minimale de l'entry dans la liste des items
  * @param {Map<string, any>} dataArray - Liste d'items a traiter
  * @param {DescriptionGraphiqueDTO} graphInfo - Les infos du graph selons le fichier de config
  * @returns {undefined | number} la valeur la plus basse
  */
  public getMinTotalValueFromDataArray (dataArray: Map<string, any>, graphInfo: DescriptionGraphiqueDTO): number | undefined {
    let lowestValue: undefined | number;
    dataArray.forEach(element => {
      element.forEach((entry: any) => {
        if (entry.total !== undefined) {
          const value = this.getLowestValueFromEntry(entry, graphInfo);
          if (lowestValue === undefined) {
            lowestValue = value;
          } else if (value < lowestValue) {
            lowestValue = value;
          }
        }
      });
    });
    return lowestValue;
  }

  /**
  * Va chercher la valeur minimale dand l'item
  * @param {any} entry - Liste d'items a traiter
  * @param {DescriptionGraphiqueDTO} graphInfo - Les infos du graph selons le fichier de config
  * @returns {number} la valeur la plus basse
  */
  private getLowestValueFromEntry (entry: any, graphInfo: DescriptionGraphiqueDTO): number {
    let minimalValue = entry.total as number;
    if (graphInfo.moyennes !== undefined) {
      if (graphInfo.moyennes.mrc !== undefined) {
        const moyenneMrc = entry[graphInfo.moyennes.mrc] as number;
        if (moyenneMrc < minimalValue) {
          minimalValue = moyenneMrc;
        }
      }
      if (graphInfo.moyennes.region !== undefined) {
        const moyenneRegion = entry[graphInfo.moyennes.region] as number;
        if (moyenneRegion < minimalValue) {
          minimalValue = moyenneRegion;
        }
      }
      if (graphInfo.moyennes.globale !== undefined) {
        const moyenneGlobale = entry[graphInfo.moyennes.globale] as number;
        if (moyenneGlobale < minimalValue) {
          minimalValue = moyenneGlobale;
        }
      }
    }
    return minimalValue;
  }

  /**
  * Va chercher la valeur maximale de total dans la liste des items
  * @param {Map<string, any>} dataArray - Liste d'items a traiter
  * @param {DescriptionGraphiqueDTO} graphInfo - Les infos du graph selons le fichier de config
  * @returns {undefined | number} la valeur la plus haute
  */
  public getMaxTotalValueFromDataArray (dataArray: Map<string, any>, graphInfo: DescriptionGraphiqueDTO): number | undefined {
    let highestValue: undefined | number;
    dataArray.forEach(element => {
      element.forEach((entry: any) => {
        if (entry.total) {
          const value = this.getHighestValueFromEntry(entry, graphInfo);
          if (highestValue === undefined) {
            highestValue = value;
          } else if (value > highestValue) {
            highestValue = value;
          }
        }
      });
    });
    return highestValue;
  }

  /**
  * Va chercher la valeur maximale dand l'item
  * @param {any} entry - Liste d'items a traiter
  * @param {DescriptionGraphiqueDTO} graphInfo - Les infos du graph selons le fichier de config
  * @returns {number} la valeur la plus basse
  */
  private getHighestValueFromEntry (entry: any, graphInfo: DescriptionGraphiqueDTO): number {
    let maximalValue = entry.total as number;
    if (graphInfo.moyennes !== undefined) {
      if (graphInfo.moyennes.mrc !== undefined) {
        const moyenneMrc = entry[graphInfo.moyennes.mrc] as number;
        if (moyenneMrc > maximalValue) {
          maximalValue = moyenneMrc;
        }
      }
      if (graphInfo.moyennes.region !== undefined) {
        const moyenneRegion = entry[graphInfo.moyennes.region] as number;
        if (moyenneRegion > maximalValue) {
          maximalValue = moyenneRegion;
        }
      }
      if (graphInfo.moyennes.globale !== undefined) {
        const moyenneGlobale = entry[graphInfo.moyennes.globale] as number;
        if (moyenneGlobale > maximalValue) {
          maximalValue = moyenneGlobale;
        }
      }
    }
    return maximalValue;
  }

  /**
  * Va chercher toutes les années de recensements possibles dans les données
  * @param {Array<any>} graphData - Liste d'items a traiter
  * @returns {Array<string>} les années de recensements possibles
  */
  public getAllAnneesRecensements (graphData: Array<any>): Array<string> {
    const annees = new Set<string>();
    graphData.forEach((dataItem: any) => {
      annees.add(dataItem.anneeRecensement);
    });
    return Array.from(annees);
  }

  /**
  * Retourne les données formatées séparées aux milliers
  * @param {number} number - Le nombre à formater
  * @returns {string} Data formatées en string avec les séparations aux milliers
  */
  public formatNombreAAfficher (number: number, decimalDemande: number): string {
    return number.toFixed(decimalDemande).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ' ');
  }

  /**
   * Retourne les données compressée
   */
  public formatBigNumber (number: number): string {
    // if (number < 1000000000) {
    //   return this.formatNombreAAfficher(number, 0);
    // }
    const si = [
      // { v: 1E3, s: 'K' },
      { v: 1E6, s: 'M' },
      { v: 1E9, s: 'B' },
      { v: 1E12, s: 'T' },
      { v: 1E15, s: 'P' },
      { v: 1E18, s: 'E' }
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
      if (number >= si[index].v) {
        break;
      }
    }
    return (number / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[index].s;
  }
}
