
import { EndroitSelectionHelper } from '@/helpers/EndroitSelectionHelper';
import { defineComponent, inject, PropType, Ref, ref } from 'vue'
import { GraphErrorDTO } from '../DTO/GraphErrorDTO';

export default defineComponent({
  name: 'EndroitError',
  props: {
    erreurs: {
      type: Map as PropType<Map<string, GraphErrorDTO>>,
      required: true
    }
  },
  computed: {
    erreursList () {
      return Array.of(...this.erreurs.values()).map(error => {
        return { showDetails: ref(false), ...error }
      });
    }
  },
  methods: {
    toggleDetails (error: { showDetails: Ref<boolean>, [key: string]: any }) {
      error.showDetails.value = !error.showDetails.value
    }
  },
  setup () {
    const endroitSelectionHelper = inject('endroitSelectionHelper') as Ref<EndroitSelectionHelper>
    const selectedEndroit = endroitSelectionHelper.value.getAllSelectedEndroits();
    return {
      selectedEndroit
    }
  }
})
