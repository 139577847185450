import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72f3bd78"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "error-box"
}
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputNumber = _resolveComponent("InputNumber")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.errorMessage.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionProp.reponsesPossibles, (reponse) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "margin-entre-reponses-questionnaire",
        key: reponse
      }, [
        _createElementVNode("label", {
          for:  reponse.texteReponse
        }, _toDisplayString(reponse.texteReponse), 9, _hoisted_2),
        _createVNode(_component_InputNumber, {
          onInput: _ctx.onValueChange,
          modelValue: _ctx.value[reponse.texteReponse],
          "onUpdate:modelValue": ($event: any) => ((_ctx.value[reponse.texteReponse]) = $event),
          mode: "decimal",
          min: 0,
          showButtons: "",
          step: 0.5,
          minFractionDigits: 1,
          buttonLayout: "horizontal",
          decrementButtonclass: "p-button-danger",
          incrementButtonclass: "p-button-success",
          incrementButtonIcon: "pi pi-plus",
          decrementButtonIcon: "pi pi-minus"
        }, null, 8, ["onInput", "modelValue", "onUpdate:modelValue"])
      ]))
    }), 128))
  ], 64))
}