
import { EndroitDTO } from '@/DTO/EndroitDTO';
import { defineComponent, inject, Ref } from 'vue'
import { ActionBtn } from '../DTO/ActionBtn';
import { EndroitSelectionHelper } from '../helpers/EndroitSelectionHelper';
export default defineComponent({
  name: 'EndroitsSelectionnes',
  props: {
    btnAction: {
      type: ActionBtn,
      required: true
    }
  },
  emits: [
    'btnEndroitPressed'
  ],
  methods: {
    conversionStringANum (val: string) {
      return Number.parseInt(val);
    },
    toggleEndroit (id: number) {
      if (this.endroitsDesactives.includes(id)) {
        this.endroitSelectionHelper.activateEndroit(id.toString()).then(() => {
          this.$emit('btnEndroitPressed')
        });
      } else {
        this.endroitSelectionHelper.deactivateEndroit(id.toString()).then(() => {
          this.$emit('btnEndroitPressed')
        });
      }
    },
    remove (end: EndroitDTO, id: number) {
      this.endroitSelectionHelper.removeEndroit(end, id).then(() => {
        this.$emit('btnEndroitPressed')
      });
    },
    supprimerTout () {
      this.endroitSelectionHelper.removeAllEndroit().then(() => {
        this.endroits = [];
      });
    }
  },
  setup () {
    const endroits = inject('endroits') as Ref<Array<EndroitDTO>>
    const endroitsDesactives = inject('endroitsDesactives') as Ref<Array<number>>
    const endroitSelectionHelper = inject('endroitSelectionHelper') as Ref<EndroitSelectionHelper>
    return {
      endroits,
      endroitsDesactives,
      endroitSelectionHelper
    }
  }
})

