
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Login',
  data () {
    return {
      error: '',
      email: '',
      password: ''
    }
  },
  methods: {
    async login () {
      const token = await this.$recaptcha('login')
      this.error = '';

      if (!this.email) {
        this.error = 'Adresse courriel requise.';
      } else if (!this.validEmail(this.email)) {
        this.error = 'Adresse courriel invalide.';
      }
      if (!this.password && !this.error) {
        this.error = 'Mot de passe requis.';
      }

      const loginBody = {
        email: this.email,
        password: this.password,
        token
      };
      if (!this.error) {
        this.$store.dispatch('auth/login', loginBody).then(() => {
          localStorage.setItem('email', loginBody.email);
          location.reload();
        })
          .catch(() => {
            this.error = 'L’adresse courriel ou le mot de passe entré est incorrect.';
          });
      }
    },
    validEmail: function (email: string) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  }
})
