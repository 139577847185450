import { nextTick, createApp, App } from 'vue';
import LoadingVueComponent from './Loading.vue'

export class LoadingHandler {
    private mainApp: any;
    private options: any;
    private loadingApp?: App;
    private loadingContainer?: HTMLElement;

    public constructor (app: any, options: any) {
      this.mainApp = app;
      this.options = options;
    }

    public startLoading () {
      nextTick(() => {
        this.loadingApp = createApp(LoadingVueComponent)
        this.loadingContainer = document.createElement('div');
        this.loadingContainer.className = 'LoadingContainer';
        this.loadingApp.mount(this.loadingContainer);
        document.body.appendChild(this.loadingContainer)
      })
    }

    public stopLoading () {
      nextTick(() => {
        if (this.loadingApp !== undefined) {
          this.loadingApp.unmount();
        }
        if (this.loadingContainer !== undefined) {
          this.loadingContainer.parentNode!.removeChild(this.loadingContainer)
        }
        this.loadingApp = undefined;
        this.loadingContainer = undefined;
      })
    }
}
