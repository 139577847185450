import { EndroitDTO } from '@/DTO/EndroitDTO';
import axios from 'axios'
import { Module } from 'vuex'
const endroit: Module<any, any> = {
  namespaced: true,
  state: {
    endroits: new Map<string, EndroitDTO>()
  },
  getters: {
    getEndroit: (state) => (id: string) => {
      /* if (sessionStorage.getItem('endroits') !== null && JSON.parse(sessionStorage.getItem('endroits')).length > 0) {
        state.endroits = new Map(JSON.parse(sessionStorage.getItem('endroits')));
      } */
      return state.endroits.get(id);
    },
    getEndroits: (state) => {
      /* if (sessionStorage.getItem('endroits') !== null && JSON.parse(sessionStorage.getItem('endroits')).length > 0) {
          return Array.from(new Map(JSON.parse(sessionStorage.getItem('endroits'))).values());
        } else {
          const endroitsLocal = Array.from((state.endroits as Map<string, EndroitDTO>).entries());
          sessionStorage.setItem('endroits', JSON.stringify(endroitsLocal));
          return Array.from(new Map(endroitsLocal).values());
        } */
      return Array.from((state.endroits as Map<string, EndroitDTO>).values());
    }
  },
  actions: {
    uploadEndroits ({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios.request({
          url: '/uploadEndroits',
          method: 'post',
          baseURL: process.env.VUE_APP_API_URL,
          headers: {
            Authorization: axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
          },
          data: body
        }).then((response: any) => {
          resolve(response)
        }).catch((err: any) => {
          reject(err)
        })
      })
    },
    getAllEndroits ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.request({
          url: '/allRecensementParEndroits',
          method: 'get',
          baseURL: process.env.VUE_APP_API_URL,
          headers: {
            Authorization: axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
          }
        }).then((response: any) => {
          commit('setEndroit', { data: response.data })
          resolve(response)
        }).catch((err: any) => {
          reject(err)
        })
      })
    },
    getAllChildOfEndroit ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.request({
          url: '/getEndroitsEnfants/' + id,
          method: 'get',
          baseURL: process.env.VUE_APP_API_URL,
          headers: {
            Authorization: axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
          }
        }).then((response: any) => {
          resolve(response.data)
        }).catch((err: any) => {
          reject(err)
        })
      })
    },
    getEndroit ({ commit }, idEndroit) {
      return new Promise((resolve, reject) => {
        axios.request({
          url: '/endroitData/' + idEndroit,
          method: 'get',
          baseURL: process.env.VUE_APP_API_URL,
          headers: {
            Authorization: axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
          }
        }).then((response: any) => {
          resolve(response)
        }).catch((err: any) => {
          reject(err)
        })
      })
    },
    getEndroitsByCriteres ({ commit }, criteres) {
      return new Promise((resolve, reject) => {
        axios.request({
          url: '/getEndroitsByCriteres',
          method: 'post',
          baseURL: process.env.VUE_APP_API_URL,
          headers: {
            Authorization: axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
          },
          data: criteres
        }).then((response: any) => {
          resolve(response)
        }).catch((err: any) => {
          reject(err)
        })
      })
    }
  },

  mutations: {
    setEndroit (state, { data }) {
      (data as Array<EndroitDTO>).forEach(element => {
        state.endroits.set(element._id, element);
      });
    }
  }
}

export default endroit
